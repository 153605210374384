<script>
import VueDraggableResizable from 'vue-draggable-resizable'
// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  name: 'DocumentField',
  components: {
    VueDraggableResizable
  },
  props: ['field'],
  data() {
    return {
      percentX: this.field.percentX,
      percentY: this.field.percentY,
      percentWidth: this.field.percentWidth,
      percentHeight: this.field.percentHeight
    }
  },
  methods: {
    onActivated: function() {
      if(["SignatureField", "InitialsField"].includes(this.field.type)) {
        let newField = this.field
        newField.show = true
        this.$store.commit('updateField', newField)
      } else if(this.field.type == "TextField") {
        let newField = this.field
        newField.textFieldModal = true
        this.$store.commit('updateField', newField)
      }
    }
  },
  computed: {
    posX: function() {
      const pdfContainer = document.getElementsByTagName("canvas")[0].getBoundingClientRect()
      return Math.floor((pdfContainer.width * this.percentX) / 100)
    },
    posY: function() {
      const pdfContainer = document.getElementsByTagName("canvas")[0].getBoundingClientRect()
      return Math.floor((pdfContainer.height * this.percentY) / 100)
    },
    width: function() {
      const pdfContainer = document.getElementsByTagName("canvas")[0].getBoundingClientRect()
      return Math.floor((pdfContainer.width * this.percentWidth) / 100)
    },
    height: function() {
      const pdfContainer = document.getElementsByTagName("canvas")[0].getBoundingClientRect()
      return Math.floor((pdfContainer.height * this.percentHeight) / 100)
    },
    fieldFillClass: function() {
      if (this.field.drawnImage == null && (this.field.value == null || this.field.value == "")) {
        return "esign-field-not-filled vdr"
      } else {
        return "esign-field-filled vdr"
      }
    }
  }
}
</script>

<template>
  <vue-draggable-resizable :w="width" :h="height" minWidth="200" minHeight="40" :x="posX" :y="posY"  :parent="true" @activated="onActivated" :draggable="false" :resizable="false" :class-name="fieldFillClass">
    <div v-if="field.type == 'SignatureField'">
      <img v-if="field.drawnImage" :src="field.drawnImage" :width="width" :height="height"/>
      <div v-else>
        <span>
          <img src="https://res.cloudinary.com/docketwise/image/upload/v1666117839/e-signature/signature-icon_ujo2x9.svg" />
        </span>
        Click here...
      </div>
    </div>

    <div v-if="field.type == 'InitialsField'">
      <span class="esign-viewer-initials-field" v-if="field.value">{{ field.value }}</span>
      <span v-else>
        <img src="https://res.cloudinary.com/docketwise/image/upload/v1666126838/e-signature/initials-icon_xq4ms5.svg" />
        Click here...
      </span>
    </div>

    <div class="esign-viewer-text-field" v-if="field.type == 'DateField'">
      <span>
        <img src="https://res.cloudinary.com/docketwise/image/upload/v1666126995/e-signature/calendar-icon_iyx1mr.svg" />
      </span>
      {{ field.value }}
    </div>

    <div v-if="field.type == 'TextField'">
      <span class="esign-viewer-text-field" v-if="field.value">{{ field.value }}</span>
      <span v-else>
        <img src="https://res.cloudinary.com/docketwise/image/upload/v1666127351/e-signature/text-icon_s7fzcg.svg" />
        Click here...
      </span>
    </div>
  </vue-draggable-resizable>
</template>
