<script>
import { computePosition, autoPlacement, shift, offset } from '@floating-ui/dom'
import './text-edit-iq.scss'
import actions from './actions.js'
import IqApi from './iq-api.js'
import MenuItem from './menu-item.vue'

export default {
  name: 'text-edit-iq',
  components: { MenuItem },
  props: {
    textEditClass: {
      type: String,
      default: ''
    },
    textEditValue: {
      type: String,
      default: ''
    },
    textEditType: {
      validator (value) {
        const validTypes = ['textarea', 'input']
        return validTypes.includes(value)
      },
      default: 'textarea'
    }
  },
  emits: ['update:text-edit-value'],
  data () {
    return {
      isMenuVisible: false,
      items: Object.values(actions)
    }
  },
  computed: {
    textEditElement () {
      return document.querySelector(`#${this.elementId}`)
    },
    isTypeTextarea () {
      return this.textEditType === 'textarea'
    },
    isTypeInput () {
      return this.textEditType === 'input'
    }
  },
  mounted () {
    this.addListenerToCloseMenu()
  },
  methods: {
    handleInput (event) {
      this.$emit('update:text-edit-value', event.target.value)
    },
    async handleAction (action) {
      const [actionMethod, param] = action.split(':')
      await this[actionMethod](param)
    },
    async improveWriting () {
      const text = this.getElementContent()
      await IqApi.improveWriting(text)
      console.log('Action "Improve writing" executed')
    },
    async changeTone (tone) {
      const text = this.getElementContent()
      await IqApi.changeTone(text, tone)
      console.log('Action "Change Tone" executed')
    },
    async translateTo (language) {
      const text = this.getElementContent()
      await IqApi.translateTo(text, language)
      console.log('Action "Translate To" executed')
    },
    async makeShorter () {
      const text = this.getElementContent()
      await IqApi.makeShorter(text)
      console.log('Action "Make Shorter" executed')
    },
    async makeLonger () {
      const text = this.getElementContent()
      await IqApi.makeLonger(text)
      console.log('Action "Make Longer" executed')
    },
    async summarize () {
      const text = this.getElementContent()
      await IqApi.summarize(text)
      console.log('Action "Summarize" executed')
    },
    async suggestActionItems () {
      const text = this.getElementContent()
      await IqApi.suggestActionItems(text)
      console.log('Action "Suggest Action Items" executed')
    },
    async explain () {
      const text = this.getElementContent()
      await IqApi.explain(text)
      console.log('Action "Explain" executed')
    },
    addListenerToCloseMenu () {
      const _this = this
      document.addEventListener('click', function (event) {
        const { target } = event
        if (_this.$refs.button !== target && !_this.$refs.menu.contains(target)) {
          _this.hideMenu()
        }
      })
    },
    async updateMenuPosition () {
      const { x, y } = await computePosition(this.$refs.button, this.$refs.menu, {
        placement: 'bottom',
        middleware: [offset(6), autoPlacement(), shift()]
      })
      this.$refs.menu.style.left = `${x}px`
      this.$refs.menu.style.top = `${y}px`
    },
    async showMenu () {
      this.isMenuVisible = true
      this.$refs.menu.style.display = 'block'
      await this.updateMenuPosition()
    },
    async toggleMenu () {
      if (this.isMenuVisible) {
        this.hideMenu()
      } else {
        await this.showMenu()
      }
    },
    hideMenu () {
      this.isMenuVisible = false
      this.$refs.menu.style.display = ''
      this.$refs.menuItems.forEach((menuItem) => menuItem.hideSubmenu())
    },
    getElementContent () {
      return this.textEditElement.value
    },
    setElementContent (text) {
      this.textEditElement.value = text
      this.textEditElement.dispatchEvent(new Event('input'))
    }
  }
}
</script>

<template>
  <div class="text-edit-iq-component">
    <textarea
      v-if="isTypeTextarea"
      :class="textEditClass"
      :value="textEditValue"
      v-bind="$attrs"
      @input="handleInput"
    />
    <input
      v-if="isTypeInput"
      :class="textEditClass"
      :value="textEditValue"
      v-bind="$attrs"
      @input="handleInput"
    >
    <button
      id="iq-button"
      ref="button"
      class="iq-menu-button"
      @click="toggleMenu"
    />
    <ul
      id="iq-menu"
      ref="menu"
      class="iq-menu"
    >
      <MenuItem
        v-for="(item, index) in items"
        ref="menuItems"
        :key="index"
        :icon-src="item.iconSrc"
        :text="item.text"
        :children="item.children"
        :action="item.action"
        @action="handleAction"
      />
    </ul>
  </div>
</template>
