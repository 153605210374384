<script>
import './event-color-picker.scss'
export default {
  props: ['initialColor'],
  data: function () {
    return {
      colors: [
        { value: '#6D0082', name: 'PurpleNew1' },
        { value: '#A440B8', name: 'PurpleNew2' },
        { value: '#E894F9', name: 'PurpleNew3' },
        { value: '#673AB7', name: 'Purple' },
        { value: '#8966C6', name: 'PurpleNew4' },
        { value: '#B39ED8', name: 'PurpleNew5' },
        { value: '#3F51B5', name: 'Blue' },
        { value: '#2196F3', name: 'Light Blue' },
        { value: '#5FA4E9', name: 'BlueNew1' },
        { value: '#009BAF', name: 'CyanNew1' },
        { value: '#00BCD4', name: 'Cyan' },
        { value: '#45EAFF', name: 'CyanNew2' },
        { value: '#009688', name: 'Green' },
        { value: '#00D96C', name: 'GreenNew1' },
        { value: '#77E7AE', name: 'GreenNew2' },
        { value: '#528F0C', name: 'LightGreenNew1' },
        { value: '#8BC34A', name: 'Light Green' },
        { value: '#B2EF75', name: 'LightGreenNew1' },
        { value: '#DBB700', name: 'YellowNew1' },
        { value: '#F5CC00', name: 'YellowNew2' },
        { value: '#FFEB3B', name: 'Yellow' },
        { value: '#E3A62A', name: 'OrangeYellowNew1' },
        { value: '#FFC143', name: 'OrangeYellowNew2' },
        { value: '#FFD47D', name: 'OrangeYellowNew3' },
        { value: '#DE8400', name: 'OrangeNew1' },
        { value: '#FF9800', name: 'Orange' },
        { value: '#FFB44A', name: 'OrangeNew2' },
        { value: '#E91E63', name: 'Pink' },
        { value: '#FF5E95', name: 'PinkNew1' },
        { value: '#FF9CBE', name: 'PinkNew2' },
        { value: '#AC0C00', name: 'RedNew1' },
        { value: '#F44336', name: 'Red' },
        { value: '#F96F6F', name: 'RedNew2' },
        { value: '#795548', name: 'Brown' },
        { value: '#AC705B', name: 'BrownNew1' },
        { value: '#E8AD99', name: 'BrownNew2' },
        { value: '#000000', name: 'Black' },
        { value: '#607D8B', name: 'Grey' },
        { value: '#A8A8A8', name: 'GreyNew1' }
      ],
      selectedColor: null
    }
  },
  mounted: function () {
    this.selectedColor = this.initialColor == null ? '#2196F3' : this.initialColor
  },
  methods: {
    setColor: function (colorValue) {
      this.selectedColor = colorValue
    }
  }
}
</script>
<template>
  <div>
    <span
      v-for="color in colors"
      :key="color.name"
      :class="{ selected: selectedColor == color.value }"
      :style="{ backgroundColor: color.value }"
      @click="setColor(color.value)"
    />
    <input
      id="calendar_color"
      name="calendar[color]"
      type="hidden"
      :value="selectedColor"
    >
  </div>
</template>
