class Mandatory2FAMessage {
  constructor () {
    this.lastShownAt = localStorage.getItem('mandatory2FAMessageLastShownAt')
    const myInstance = this

    $(document).ready(function () {
      // eslint-disable-next-line no-undef
      myInstance.user = getCurrentUser()
      if (!myInstance.user.firm) {
        return null
      }
      myInstance.firm = myInstance.user.firm
      myInstance.mandatoryDate = myInstance.user.mandatory_2fa_date || myInstance.firm.mandatory_2fa_date
      if (!location.pathname.includes('/users/sign_in') && myInstance.shouldShowMandatoryToast()) {
        myInstance.showMandatoryToast()
      }
    })
  }

  shouldShowMandatoryToast () {
    const now = new Date()
    const today8AM = new Date(now)

    today8AM.setHours(8, 0, 0, 0)

    return this.firm && this.mandatoryDate &&
      !this.firm['trialing?'] &&
      !this.user.completed_otp_config &&
      new Date(this.mandatoryDate) > now &&
      now >= today8AM &&
      (!this.lastShownAt || new Date(this.lastShownAt) < today8AM)
  }

  showMandatoryToast () {
    toastr.options = {
      tapToDismiss: false,
      timeOut: 0,
      extendedTimeOut: 0,
      onclick: function () {
        window.location = $('#2fa_settings').attr('href')
      }
    }

    const mandatoryDate = this.mandatoryDate.split('-')
    const strDate = mandatoryDate[1] + '/' + mandatoryDate[2] + '/' + mandatoryDate[0]
    toastr.warning('On ' + strDate + ' date, two-factor authentication will become mandatory for all users. Please set up your two-factor authentication <a id="2fa_settings" class="undecorated-link" href="/account/two_factor_authentication?disable_authy=1">here</a> as soon as possible to avoid any possible disruptions.')
    localStorage.setItem('mandatory2FAMessageLastShownAt', new Date().toISOString())
  }
}

export default Mandatory2FAMessage
