import { v4 as uuidv4 } from 'uuid'

export const findFilterGroup = ({ state, filterGroup }) => {
  if (!state || !filterGroup) { return null }
  return state.filterGroups.findIndex(fg => fg.id === filterGroup.id)
}

export const filterObject = () => {
  return {
    dateValue: null,
    equivalency: null,
    equivalencyOptions: null,
    hideInputText: true,
    id: uuidv4(),
    initialized: false,
    label: null,
    selectValue: null,
    type: 'singleFilterType',
    value: ''
  }
}

export const filterGroupObject = () => {
  return {
    id: uuidv4(),
    logicalOperator: {
      name: 'And',
      value: 'logic_and'
    },
    type: 'filterGroupType',
    filters: [filterObject()]
  }
}
