export const LOGIC_OPERATORS = [
  {
    name: 'And',
    value: 'logic_and'
  },
  {
    name: 'Or',
    value: 'logic_or'
  }
]
