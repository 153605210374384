<!-- AddFilterButton.vue -->
<script>
export default {
  props: ['filter', 'filterGroup', 'addFilter']
}
</script>
<template>
  <button
    title="Add filter to this group"
    class="table-query-builder__filter__add-filter-button"
    @click="addFilter"
  >
    <img
      class="table-query-builder__plus-icon"
      src="~images/custom_reports/plus-gray-icon.svg"
    >
  </button>
</template>
