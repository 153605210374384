<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'SigersSetupModal',
  components: {
    Multiselect
  },
  props: ['signatureDocument'],
  data () {
    return {
      signerOptions: [],
      signerSelected: null,
      isLoading: false,
      show: true
    }
  },
  mounted() {
    this.loadSigners()
  },
  methods: {
    loadSigners() {
      this.$store.dispatch('ajaxLoadSigners', this.signatureDocument.id)
    },
    async getSigners (query) {
      this.isLoading = true
      const usersResponse = await axios.get(`/users.json?q=${escape(query)}`)
      const contactsResponse = await axios.get(`/contacts.json?q=${escape(query)}&skip_create_contact=true`)
      const users = {
        signatureType: 'Firm Members',
        items: JSON.parse(usersResponse.data.users)
      }
      const contacts = {
        signatureType: 'Firm Contacts',
        items: contactsResponse.data.items
      }
      this.signerOptions = [users, contacts]
      this.isLoading = false
    },
    addSignature () {
      if(this.signerSelected == null) return
      if(this.signerSelected.hasOwnProperty('user_type') && this.validateUserExists()) {
        alert(`The ${this.signerSelected.full_name} firm member has already been added`)
        return
      }
      if(!this.signerSelected.hasOwnProperty('user_type') && this.validateContactExists()) {
        alert(`The ${this.signerSelected.full_name} contact has already been added`)
        return
      }

      const signer = this.signerSelected
      const userId = signer.hasOwnProperty('user_type') ? signer.id : null
      const contactId = signer.hasOwnProperty('user_type') ? null : signer.id
      this.$store.commit('newSigner', {
        id: null,
        name: signer.full_name,
        email: signer.email,
        phoneNumber: signer.number,
        userId: userId,
        contactId: contactId
      })
      this.signerSelected = null
    },
    validateUserExists() {
      const userSigners = this.signers.filter(signer => signer.userId != null)
      return userSigners.some(signer => signer.userId == this.signerSelected.id)
    },
    validateContactExists() {
      const contactSigners = this.signers.filter(signer => signer.contactId != null)
      return contactSigners.some(signer => signer.contactId == this.signerSelected.id)
    },
    async removeSigner(signerIndex) {
      this.$store.dispatch('ajaxRemoveSigner', signerIndex)
    },
    async handlePrepareSignature() {
      try {
        if(this.signatureDocument.id != "") return
        if(this.signers.length == 0) {
          alert("Please add at least one signer")
          return
        }

        const signatureDocumentResponse = await axios.post(`/documents/${this.signatureDocument.document.id}/signature_documents`)
        for (const signer of this.signers) {
          let signerData = {
            "document_signer": {
              name: signer.name,
              email: signer.email,
              phone_number: signer.phoneNumber,
              user_id: signer.userId,
              contact_id: signer.contactId
            }
          }
          await axios.post(`/document_signers?signature_document_id=${signatureDocumentResponse.data.id}`, signerData)
        }
        window.location.href = `/signature_documents/${signatureDocumentResponse.data.id}/edit`
      } catch(err) {
        if(err.response && err.response.status == 400) {
          alert(err.response.data.message)
        }
      }
    },
    async handleEditSignatureDocument() {
      if(this.signers.length == 0) { 
        alert("Please add at least one signer")
        return
      }

      for (const signer of this.signers) {
        if(signer.id == null) {
          const signerData = {
            "document_signer": {
              name: signer.name,
              email: signer.email,
              phone_number: signer.phoneNumber,
              user_id: signer.userId,
              contact_id: signer.contactId
            }
          }
          await axios.post(`/document_signers?signature_document_id=${this.signatureDocument.id}`, signerData)
        }
      }
      window.location.href = `/signature_documents/${this.signatureDocument.id}/edit`
    },
    hideModal() {
      this.show = false
    }
  },
  computed: {
    ...mapGetters(['signers'])
  }
}
</script>

<template>
  <div v-show="show">
    <div class="modal show" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="hideModal">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">E-Signature Setup</h4>
          </div>
          <div class="modal-body user-invite-modal esign-request-modal-content pt-1">
            <div v-if="signatureDocument.status != 'signed'">
              <label class="esign-field-details-label">Please select the individuals that will need to sign this document</label>
              <multiselect
                placeholder="Search Firm Members or Contacts"
                v-model="signerSelected"
                label="full_name"
                track-by="full_name"
                :options="signerOptions"
                :loading="isLoading"
                :internal-search="false"
                open-direction="bottom"
                :searchable="true"
                group-values="items"
                group-label="signatureType"
                :group-select="false"
                :show-labels="false"
                :show-pointer="false"
                :show-no-options="false"
                @search-change="getSigners">
              </multiselect>
              <button type="button" class="btn btn-xs btn-default esign-signers-modal-add-signer" @click="addSignature">Add Signer</button>
            </div>

            <div class="esign-signers-table-container">
              <table class="table dw-theme-settings-standard-table esign-signers-table" width="100%">
                <thead>
                  <tr>
                    <th>User or Contact</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th class="dw-theme-settings-standard-actions-column esign-signers-table-actions-clm">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(signer, index) in signers">
                    <td>{{ signer.name }}</td>
                    <td>{{ signer.email || '-' }}</td>
                    <td>{{ signer.phoneNumber || '-' }}</td>
                    <td>
                      <div class="esign-signers-table-rmv-btn" @click="removeSigner(index)">
                          <img src="https://res.cloudinary.com/docketwise/image/upload/v1666204722/e-signature/trash-can-icon_brrs66.svg" />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="signers.length == 0">
                    <td colspan="4" class="center">No signers added yet.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer mb-2">
            <button type="button" class="btn btn-default" @click="hideModal">CANCEL</button>
            <button type="button" class="btn btn-primary" :disabled="signatureDocument.status == 'signed'" @click="handleEditSignatureDocument" v-if="signatureDocument.id != ''">EDIT SIGNATURE DOCUMENT</button>
            <button type="button" class="btn btn-primary" @click="handlePrepareSignature" v-else>PREPARE SIGNATURE DOCUMENT</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
    <div class="modal-backdrop fade in"></div>
  </div>
</template>

<style lang="scss">
  .esign-signers-table-container {
    min-height: 255px;
  }
  .esign-signers-table {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .esign-signers-table-rmv-btn {
    cursor: pointer;
    text-align: center;
  }
  .esign-signers-table-actions-clm {
    text-align: center;
  }
  .esign-signers-modal-add-signer {
    background-color: #1976D2;
    color: #FFFFFF;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-top: 5px;
    padding: 3px 10px;
    &:hover {
      background-color: #1976D2 !important;
      color: #FFFFFF !important;
    }
    &:active {
      background-color: #1976D2 !important;
      color: #FFFFFF !important;
    }
    &:focus {
      background-color: #1976D2 !important;
      color: #FFFFFF !important;
    }
  }
</style>
