<!-- FilterLogicOperator.vue -->
<template>
  <span
    :class="{
      'table-query-builder__logic-operator--global': type === 'globalGroup',
      'table-query-builder__logic-operator--filter': type === 'filterGroup'
    }"
  >
    <multi-select
      v-model="mainLogicalOperator"
      class="table-query-builder__logic-operator"
      :options="options"
      :allow-empty="false"
      :show-labels="false"
      :placeholder="null"
      label="name"
      track-by="name"
      @select="update"
    />
  </span>
</template>
<script>

export default {
  name: 'FilterLogicOperator',
  props: ['filterType', 'filterGroup', 'type'],
  data () {
    return {
      options: [
        {
          name: 'And',
          value: 'logic_and'
        },
        {
          name: 'Or',
          value: 'logic_or'
        }
      ]
    }
  },
  computed: {
    mainLogicalOperator () {
      if (this.filterType === 'singleFilterLogicOperatorType') {
        return this.$store.getters.filterGroupLogicalOperator(this.filterGroup)
      } else if (this.filterType === 'filterGroupLogicOperatorType') {
        return this.$store.getters.mainLogicalOperator
      }
      return null
    }
  },
  methods: {
    update (value) {
      if (this.filterType === 'singleFilterLogicOperatorType') {
        const { filterGroup } = this
        this.$store.commit('updateFilterGroupLogicalOperator', { filterGroup, value })
      } else if (this.filterType === 'filterGroupLogicOperatorType') {
        this.$store.commit('updateMainLogicalOperator', { value })
      }
      this.$store.dispatch('updateAjaxQueryOnUiUpdate')
    }
  }
}
</script>
