
Vue.component('paginator-vue', {
  props: [
    'currentPage',
    'hasNext'
  ],
  computed: {
    isFirst: function() {
      return this.currentPage <= 1
    },
    isLast: function() {
      return !this.hasNext
    }
  },
  methods: {
    handleNext () {
      this.$parent.updatePage(this.currentPage + 1)
    },
    handlePrevious () {
      if (this.currentPage - 1 > 0) {
        this.$parent.updatePage(this.currentPage - 1)
      }
    }
  },
  template: '#paginator-vue-component'
})
