<script>
import './select2-client.scss'
export default {
  name: 'select2-client',
  props: {
    name: {
      type: String,
      required: true
    },
    fieldId: {
      type: String,
      required: true
    },
    multiple: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Type or select client'
    },
    queryParameters: {
      type: String,
      default: null
    },
    upfrontSelection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      clientId: null
    }
  },
  mounted () {
    this.initClientId()
    if (this.upfrontSelection) this.updateClientListener()
    if (this.upfrontSelection) this.broadcastClientIdToMatterSelect2()
    this.configureSelect2(this.fieldId, this.placeholder)
    this.loadClientsIntoSelect2(this.queryParameters, this.fieldId)
  },
  methods: {
    initClientId () {
      const _self = this

      const selectedValues = JSON.parse(this.queryParameters).clients_ids
      if (selectedValues !== undefined || (selectedValues && selectedValues.length !== 0)) {
        _self.clientId = JSON.parse(this.queryParameters).client_id
      }
    },
    updateClientListener () {
      const _self = this
      const bcMatter = new BroadcastChannel('update_select2_matter_id_channel')

      bcMatter.onmessage = (event) => {
        _self.clientId = event.data
        _self.loadClientsIntoSelect2(JSON.stringify({ clients_ids: [_self.clientId] }), _self.fieldId)
      }
    },
    broadcastClientIdToMatterSelect2 () {
      const field = $(`#${this.fieldId}`)
      const bc = new BroadcastChannel('update_select2_client_id_channel')

      field.on('select2:select', function (e) {
        const value = field.val()
        bc.postMessage(value)
      })

      field.on('select2:unselect', function (e) {
        bc.postMessage(null)
      })
    },
    configureSelect2 (id, placeholder) {
      $(`#${id}`).select2({
        ajax: {
          url: '/contacts',
          dataType: 'json',
          delay: 250,
          data: this.processData,
          processResults: this.processResults,
          cache: true
        },
        placeholder,
        allowClear: true
      })
    },
    processResults (data, params) {
      params.page = params.page || 1

      const contacts = data.items.map(function (r) {
        return {
          id: r.id,
          text: r.full_name
        }
      })

      return {
        results: contacts,
        pagination: {
          more: (params.page * 10) < data.total_count
        }
      }
    },
    processData (params) {
      return {
        q: params.term,
        page: params.page,
        filter: 'all',
        skip_total_count: true
      }
    },
    loadClientsIntoSelect2 (queryParameters, fieldId) {
      const selectedValues = queryParameters && JSON.parse(queryParameters).clients_ids
      if (selectedValues === undefined || selectedValues.length === 0) return

      let data = []

      $.ajax({
        url: '/contacts',
        dataType: 'json',
        data: {
          ids: selectedValues
        }
      }).done(function (response) {
        data = response.items.map(client => {
          return {
            id: client.id,
            text: client.full_name
          }
        })

        data.forEach(client => {
          const newOption = new Option(client.text, client.id, false, false)
          $(`#${fieldId}`).append(newOption).trigger('change')
        })

        $(`#${fieldId}`).val(selectedValues).change()
      })
    }
  }
}
</script>
<template>
  <select
    :id="fieldId"
    class="select2"
    :name="name"
    :multiple="multiple"
    style="width: 100%"
  />
</template>
