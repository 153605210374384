import { createStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

const store = createStore({
  state () {
    return {
      fields: {},
      fieldSelected: null,
      signers: [],
      pagesLoaded: 0
    }
  },
  mutations: {
    newField (state, field) {
      field.id = uuidv4()
      state.fields[field.id] = field
    },
    removeField (state, fieldId) {
      state.fieldSelected = null
      delete state.fields[fieldId]
    },
    updateField (state, field) {
      state.fields[field.id] = field
    },
    newSigner (state, signer) {
      state.signers.push(signer)
    },
    setSigners (state, signers) {
      state.signers = signers
    },
    setFieldSelected (state, fieldId) {
      state.fieldSelected = fieldId
    },
    incrementPageLoaded (state) {
      state.pagesLoaded = ++state.pagesLoaded
    }
  },
  getters: {
    fields (state) {
      return state.fields
    },
    field (state, fieldId) {
      return state.fields[fieldId]
    },
    fieldSelected (state) {
      return state.fieldSelected
    },
    signers (state) {
      return state.signers
    },
    pagesLoaded (state) {
      return state.pagesLoaded
    }
  },
  actions: {
    async ajaxLoadSigners ({ commit }, signatureDocumentId) {
      const fieldsResponse = await axios.get(`/document_signers.json?signature_document_id=${signatureDocumentId}`)
      const signers = []
      for (const signer of fieldsResponse.data) {
        signers.push({
          id: signer.id,
          name: signer.name,
          email: signer.email,
          phoneNumber: signer.phone_number,
          userId: signer.user_id,
          contactId: signer.contact_id,
          code: signer.code,
          submittedIpAddress: signer.submitted_ip_address,
          submittedAt: signer.submitted_at,
          notifyViaEmail: signer.notify_via_email,
          notifyViaSms: signer.notify_via_sms
        })
      }
      commit('setSigners', signers)
    },
    async ajaxRemoveSigner ({ state }, signerIndex) {
      const signer = state.signers[signerIndex]
      if (signer.id != null) {
        await axios.delete(`/document_signers/${signer.id}`)
      }
      state.signers.splice(signerIndex, 1)
    }
  }
})

export default store
