// fixes BOR-4428: https://docketwise.monday.com/boards/424470067/pulses/1117124428
// based on: https://stackoverflow.com/questions/14473769/bootstrap-drop-down-cutting-off
export function tableOptionsDropdownFix ({ toggles }) {
  const dropdownToggles = $(toggles)
  dropdownToggles.each(function (index, elem) {
    let dropdownMenu
    const dropdownToggle = $(elem)
    dropdownToggle.dropdown()
    dropdownToggle.parent().on('show.bs.dropdown', function (e) {
      const $target = $(e.target)
      const $relatedTarget = $(e.relatedTarget).parent()
      dropdownMenu = $target.find('.dropdown-menu')
      dropdownMenu.addClass('absolute-dropdown')
      dropdownMenu.css({
        position: 'absolute',
        'z-index': 10,
        top: ($relatedTarget.offset().top + 10 + $relatedTarget.height()) + 'px',
        left: ($relatedTarget.offset().left - dropdownMenu.width() - 10 + $relatedTarget.width()) + 'px' // from right
      })
      $('body').append(dropdownMenu.detach())
      dropdownMenu.css('display', 'block')
    })
    dropdownToggle.parent().on('hide.bs.dropdown', function (e) {
      if (dropdownMenu) {
        $(e.target).append(dropdownMenu.detach())
        dropdownMenu.hide()
      }
    })
  })
}

export function tableOptionsDropdownCleanup () {
  $('body .absolute-dropdown').attr('style', '')
}

const patches = {
  tableOptionsDropdownFix,
  tableOptionsDropdownCleanup
}

export default patches
