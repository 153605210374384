<script>
import './share-invoices-modal.scss'
import { get } from 'lodash'

function initialState () {
  return {
    activeStep: 'initial',
    loading: null,
    modalTitle: 'Share Invoice(s)',
    selectedInvoices: [],
    selectedInvoicesIds: [],
    shareEach: null,
    shareMethod: null,
    selectedContactId: null,
    selectedContactObject: null
  }
}

export default {
  props: ['greeting'],
  data: function () {
    return initialState()
  },
  computed: {
    disableFirstStep () {
      const { shareEach, shareMethod } = this
      return shareEach === null || shareMethod === null
    },
    disableShareInvoices () {
      const { shareEach, shareMethod, loading } = this
      return shareEach === null || shareMethod === null || loading === true
    }
  },
  mounted: function () {
    const that = this

    // on close modal listener
    const modalEl = $('#bulk-share-invoices-modal')
    modalEl.on('hidden.bs.modal', function () {
      that.resetWindow()
    })
  },
  methods: {
    shareEachChangeHandler: function () {
      this.initSelect2Recipient()
    },
    initSelect2Recipient: function () {
      const that = this
      const select2Recipient = new ApplicationParty()
      select2Recipient.initializeSelect2(
        '/contacts/new',
        '#search-contact',
        true
      )

      select2Recipient.displayClientModal(
        '/contacts/new',
        false,
        '#search-contact'
      )

      $('#search-contact').on('change', function () {
        that.selectedContactId = this.value
      })
    },
    resetWindow: function () {
      // reset state to its initial state
      Object.assign(this.$data, initialState())
      // init single recipient select2
      this.initSelect2Recipient()
    },
    resetHandler: function (event) {
      event.preventDefault()
      this.resetWindow()
    },
    setActiveStep () {
      if (this.shareEach === 'true' && this.shareMethod === 'email') {
        this.activeStep = 'reviewEmailsPage'
        this.fetchPrepopulationInformation()
        this.modalTitle = 'Review Emails'
      } else if (this.shareEach === 'true' && this.shareMethod === 'text') {
        this.fetchPrepopulationInformation()
        this.activeStep = 'reviewPhoneNumbersPage'
        this.modalTitle = 'Review Phone Numbers'
      } else if (this.shareEach === 'false' && this.shareMethod === 'email' && this.selectedContactId !== null) {
        this.fetchInvoicesId()
        this.fetchSelectContactData()
        this.activeStep = 'reviewSingleEmailPage'
      } else if (this.shareEach === 'false' && this.shareMethod === 'text' && this.selectedContactId !== null) {
        this.fetchInvoicesId()
        this.fetchSelectContactData()
        this.activeStep = 'reviewSinglePhoneNumberPage'
      }
    },
    fetchInvoicesId () {
      const invoicesIds = []

      $('.form-bulk-action-checkbox:checked').each(function () {
        invoicesIds.push($(this).attr('data-id'))
      })

      this.selectedInvoicesIds = invoicesIds
    },
    fetchPrepopulationInformation () {
      const that = this
      const invoicesIds = []

      $('.form-bulk-action-checkbox:checked').each(function () {
        invoicesIds.push($(this).attr('data-id'))
      })

      this.loading = true

      $.ajax({
        type: 'POST',
        url: '/invoices_bulk_actions/contacts_invoices_data',
        data: { invoices_ids: invoicesIds },
        success: function (data) {
          that.loading = null

          that.selectedInvoices = data.map(se => {
            // set phone number to string if null
            let mobilePhoneNumber = get(se, 'contact.mobile_phone_number', null)
            mobilePhoneNumber = mobilePhoneNumber === null ? '' : mobilePhoneNumber

            return {
              invoice_id: se.id,
              contact_id: se.contact_id,
              contact_email: se.contact.email,
              contact_name: se.contact.full_name,
              contact_sms_consent: se.contact.sms_consent,
              contact_blacklist: se.contact.blacklist,
              // limit string coming data to 10 digits
              contact_mobile_phone_number: mobilePhoneNumber.substring(0, 10)
            }
          })
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          console.warn(`Status: ${textStatus}`)
          console.warn(`Error Thrown: ${errorThrown}`)
          toastr.error('Something went wrong')
          this.loading = null
          that.resetWindow()
        }
      })
    },
    mapInvoicesIds () {
      const invoicesIds = this.selectedInvoices.map(si => si.invoice_id)
      return invoicesIds
    },
    shareBulkIndividually ({ channel }) {
      const that = this
      that.loading = true
      const data = {
        channel,
        invoices_ids: this.mapInvoicesIds(),
        selected_invoices_data: JSON.stringify(this.selectedInvoices)
      }

      $.ajax({
        type: 'POST',
        url: '/invoices_bulk_actions/share_bulk',
        data,
        success: function (data) {
          toastr.success('Invoice invitations sent!')
          $('#bulk-share-invoices-modal').modal('hide')
          that.resetWindow()
          that.loading = null
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          console.warn(`Status: ${textStatus}`)
          console.warn(`Error Thrown: ${errorThrown}`)
          toastr.error('Something went wrong')
          that.loading = null
        }
      })
    },
    shareMultipleInvoicesToASingleRecipient ({ channel }) {
      const that = this
      that.loading = true
      const otherContactValue = channel === 'email'
        ? that.selectedContactObject.email
        : that.selectedContactObject.mobile_phone_number

      $.ajax({
        type: 'GET',
        url: '/invoices_bulk_actions/share',
        data: { channel, ids: that.selectedInvoicesIds, contact_id: that.selectedContactId, other_contact_value: otherContactValue },
        success: function (data) {
          toastr.success('Invoices invitation sent!')
          $('#bulk-share-invoices-modal').modal('hide')
          that.resetWindow()
          that.loading = null
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          const errObj = XMLHttpRequest.responseJSON
          console.warn(`Status: ${textStatus}`)
          console.warn(`Error Thrown: ${errorThrown}`)
          toastr.error(errObj.message || 'Something went wrong')
          that.loading = null
        }
      })
    },
    fetchSelectContactData () {
      const that = this
      that.loading = true

      $.ajax({
        type: 'GET',
        url: `/contacts/${that.selectedContactId}`,
        success: function (data) {
          const { contact } = data
          that.loading = null
          that.selectedContactObject = JSON.parse(contact)
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          console.warn(`Status: ${textStatus}`)
          console.warn(`Error Thrown: ${errorThrown}`)
          toastr.error('Something went wrong')
          that.loading = null
        }
      })
    },

    checkShareSingleEmailForm: function (e) {
      this.shareMultipleInvoicesToASingleRecipient({ channel: 'email' })
      e.preventDefault()
    },
    checkShareEmailsForm: function (e) {
      this.shareBulkIndividually({ channel: 'email' })
      e.preventDefault()
    },
    checkSharePhoneNumbersForm: function (e) {
      this.shareBulkIndividually({ channel: 'sms' })
      e.preventDefault()
    },
    checkShareSingleMobilePhoneNumberForm: function (e) {
      this.shareMultipleInvoicesToASingleRecipient({ channel: 'sms' })
      e.preventDefault()
    },
    isNumber: function (event) {
      const evt = (event) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
<template>
  <div
    id="bulk-share-invoices-modal"
    class="modal"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="share-invoices-modal__title">
            {{ modalTitle }}
          </h4>
        </div>
        <div class="share-invoices-modal">
          <div
            v-if="loading == true"
            class="loading"
          />
          <div
            v-if="activeStep == 'initial'"
            class="share-invoices-modal__inner-container"
          >
            <div class="form-group">
              <label class="share-invoices-modal__question ">How do you want to select the recipient(s)?</label>
            </div>
            <div class="form-group">
              <label>
                <input
                  v-model="shareEach"
                  name="shareEach"
                  title="Share each with their respective clients"
                  type="radio"
                  value="true"
                  class="required"
                >
                Share each with their respective clients
              </label>
            </div>
            <br>
            <div class="form-group">
              <label>
                <input
                  v-model="shareEach"
                  name="shareEach"
                  title="Share all with one specific client"
                  type="radio"
                  value="false"
                  class="required"
                  @change="shareEachChangeHandler"
                >
                Share all with one specific client
              </label>
            </div>
            <div
              v-if="shareEach"
              class="form-group"
            >
              <label class="share-invoices-modal__question ">How do you want to share?</label>
            </div>
            <div
              v-if="shareEach"
              class="form-group"
            >
              <label>
                <input
                  v-model="shareMethod"
                  name="shareMethod"
                  title="Email"
                  type="radio"
                  value="email"
                  class="required"
                >
                Email
              </label>
            </div>
            <br v-if="shareEach">
            <div
              v-if="shareEach"
              class="form-group"
            >
              <label>
                <input
                  v-model="shareMethod"
                  name="shareMethod"
                  title="Text Message"
                  type="radio"
                  value="text"
                  class="required"
                >
                Text Message
              </label>
            </div>
            <div
              :class="{ hide: !(shareEach !== 'true' && shareMethod !== null && loading !== true) }"
            >
              <div
                class="mt-5"
              >
                <div class="form-group">
                  <p class="share-invoices-modal__name">
                    Select a contact
                  </p>
                  <select
                    id="search-contact"
                    v-model="selectedContactId"
                    class="select2 recipient-input mt-1"
                    required="required"
                    style="width:100%"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <input
                class="btn btn-primary btn-block"
                :disabled="disableFirstStep"
                type="submit"
                value="Share Invoice(s)"
                @click="setActiveStep"
              >
            </div>
          </div>

          <div
            v-if="activeStep =='reviewEmailsPage' && loading !== true"
            class="share-invoices-modal__step-container"
          >
            <form
              @submit="checkShareEmailsForm"
            >
              <div
                v-for="invoice in selectedInvoices"
                :key="invoice.id"
                class="form-group"
              >
                <p class="share-invoices-modal__name">
                  {{ invoice.contact_name }}
                </p>
                <input
                  v-model="invoice.contact_email"
                  required
                  placeholder="Email"
                  type="email"
                  class="form-control"
                >
              </div>
              <div class="form-group">
                <input
                  class="btn btn-primary btn-block"
                  :disabled="disableShareInvoices"
                  type="submit"
                  value="Share Invoice(s)"
                >
              </div>
            </form>
          </div>

          <div
            v-if="activeStep =='reviewPhoneNumbersPage' && loading !== true"
            class="share-invoices-modal__step-container"
          >
            <form
              @submit="checkSharePhoneNumbersForm"
            >
              <div
                v-for="invoice in selectedInvoices"
                :key="invoice.id"
                class="form-group"
              >
                <p class="share-invoices-modal__name">
                  {{ invoice.contact_name }}
                </p>
                <div class="input-group">
                  <div class="input-group-addon">
                    +1
                  </div>
                  <input
                    v-model="invoice.contact_mobile_phone_number"
                    required
                    :disabled="!invoice.contact_sms_consent"
                    placeholder="Type the recipient mobile number"
                    type="text"
                    class="form-control"
                    pattern=".{10,10}"
                    title="Please enter a valid USA mobile number with 10 digits"
                    maxlength="10"
                    @keypress="isNumber($event)"
                  >
                </div>
                <span class="validation-error small-font red" v-if="invoice.contact_blacklist">
                  The mobile number you are attempting to contact has unsubscribed from texting.
                  Please have this contact respond START so they can begin receiving messages.
                </span>
                <span class="validation-error small-font red" v-else-if="!invoice.contact_sms_consent">
                  This contact has not consented to receive SMS messages.
                </span>
              </div>
              <div class="form-group">
                <input
                  class="btn btn-primary btn-block"
                  type="submit"
                  value="Share Invoice(s)"
                >
              </div>
            </form>
          </div>
          <div
            v-if="activeStep =='reviewSingleEmailPage' && loading !== true"
            class="mt-4"
          >
            <div class="form-group">
              <p class="share-invoices-modal__name">
                {{ selectedContactObject.full_name }}
              </p>
              <form
                @submit="checkShareSingleEmailForm"
              >
                <input
                  v-model="selectedContactObject.email"
                  required
                  placeholder="Email"
                  type="email"
                  class="form-control"
                >
                <input
                  class="btn btn-primary btn-block"
                  type="submit"
                  value="Share Invoice(s)"
                >
              </form>
            </div>
          </div>
          <div
            v-if="activeStep =='reviewSinglePhoneNumberPage' && loading !== true"
            class="mt-4"
          >
            <div class="form-group">
              <p class="share-invoices-modal__name">
                {{ selectedContactObject.full_name }}
              </p>
              <form
                @submit="checkShareSingleMobilePhoneNumberForm"
              >
                <div class="input-group">
                  <div class="input-group-addon">
                    +1
                  </div>
                  <input
                    v-model="selectedContactObject.mobile_phone_number"
                    required
                    placeholder="Type the recipient mobile number"
                    type="text"
                    class="form-control"
                    pattern=".{10,10}"
                    title="Please enter a valid USA mobile number with 10 digits"
                    maxlength="10"
                    @keypress="isNumber($event)"
                  >
                </div>
                <input
                  class="btn btn-primary btn-block"
                  type="submit"
                  value="Share Invoice(s)"
                >
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group">
              <button
                class="btn btn-block btn-transparent"
                aria-label="Cancel"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
