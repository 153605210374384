class EditInvite {
  revokeInviteEvent () {
    $('.revoke-form-invitation').unbind('click').click(function (event) {
      const formId = event.target.getAttribute('data-invitation-id')
      if (confirm('This cannot be undone. Are you sure you want to revoke access for this invitation?') === true) {
        $.ajax({
          url: `/form_invitations/${formId}/revoke/`,
          type: 'GET',
          success: function (response) {
            console.log(response)
          },
          error: function (response) {
            console.log(response)
          },
          complete: function (response) {
            console.log(response)
          }
        })
      }
    })
  }
}

window.EditInvite = EditInvite
