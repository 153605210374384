<!-- InputTextValue.vue -->
<script>
import { mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: ['filter', 'filterGroup'],
  data: () => {
    return {
      id: uuidv4()
    }
  },
  computed: {
    ...mapState({
      filterableColumns: state => state.filterableColumns
    }),
    value () {
      const { filterGroup, filter } = this
      return this.$store.getters.filterValue(filterGroup, filter)
    },
    isNumber () {
      const { filter } = this
      const isNumber = (filter && filter.label && filter.label.type === 'integer') || (filter && filter.label && filter.label.type === 'datetime')

      return isNumber
    },
    componentUiId () {
      return `table-query-builder__input-text-id-${this.id}`
    }
  },
  mounted () {
    if (this.isNumber === true) {
      $(`#${this.componentUiId}`).inputmask({
        alias: 'numeric',
        rightAlign: false,
        allowMinus: false
      })
    } else {
      $(`#${this.componentUiId}`).inputmask('remove')
    }
  },
  methods: {
    updateValue: function (event) {
      // let value
      const value = event.target.value
      const { filterGroup, filter } = this
      // value = value.replace(/\s+/g, ' ').trim() // removes extra spaces
      this.$store.commit('updateFilterValue', { filter, filterGroup, value })
    },
    enterHandler: function (event) {
      this.updateValue(event)
      event.target.blur()
    }
  }
}
</script>
<template>
  <input
    :id="componentUiId"
    :class="{'table-query-builder__input-number': isNumber}"
    class="table-query-builder__input-text"
    type="text"
    :value="value"
    @keyup="updateValue"
    @blur="enterHandler"
    @keyup.enter="enterHandler"
  >
</template>
