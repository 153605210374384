<script>
export default {
  name: 'base-modal',
  props: {
    modalId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div
    :id="modalId"
    role="dialog"
    class="modal fade"
  >
    <div
      role="document"
      class="modal-dialog"
    >
      <div class="modal-content small-modal">
        <div class="modal-header">
          <button
            data-dismiss="modal"
            type="button"
            class="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">
            {{ title }}
          </h4>
        </div>

        <div class="modal-body">
          <slot name="body" />
        </div>

        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.small-modal {
  max-width: 500px;
}
.medium-modal {
  max-width: 700px;
}
.large-modal {
  max-width: 900px;
}
</style>
