<script>
import pdf from 'vue-pdf'
import DocumentField from './DocumentField'
import { mapGetters } from 'vuex'

export default {
  name: 'PdfPage',
  components: {
    pdf,
    DocumentField
  },
  props: ['src', 'pageIndex', 'insertFieldType'],
  methods: {
    handleClick: function (e) {
      if(e.target.closest(".draggable")) return

      const pageEl = e.target.closest('.pdf-page-container')
      const pageRect = pageEl.getBoundingClientRect()
      const x = e.clientX - pageRect.left
      const y = e.clientY - pageRect.top
      let percentX = (x / pageRect.width) * 100
      let percentY = (y / pageRect.height) * 100
      let percentWidth = (200 / pageRect.width) * 100
      let percentHeight = (40 / pageRect.height) * 100
      percentX = Math.max(percentX, 0)
      percentY = Math.max(percentY, 0)
      percentWidth = Math.max(percentWidth, 0)
      percentHeight = Math.max(percentHeight, 0)

      this.$store.commit('newField', {
        type: this.insertFieldType,
        pageIndex: this.pageIndex,
        percentWidth: percentWidth,
        percentHeight: percentHeight,
        percentX,
        percentY,
        signature: {}
      })
    },
    handlePdfPageLoaded () {
      this.$store.commit('incrementPageLoaded')
      this.$emit('pdfPageLoaded')
    }
  },
  computed: {
    ...mapGetters(['fields']),
    fieldsByCurrentPage: function() {
      let fieldsEntries = Object.entries(this.fields)
      let fieldsFiltered = fieldsEntries.filter(([fieldId, field]) => field.pageIndex == this.pageIndex)
      return Object.fromEntries(fieldsFiltered)
    }
  }
}
</script>

<template>
  <div class="pdf-page-container" @click="handleClick">
    <pdf :src="src" :page="pageIndex" @page-loaded="handlePdfPageLoaded"/>
    <div class="document-fields-container">
      <document-field v-for="(field, index) in fieldsByCurrentPage" :field="field" :key="index"/>
    </div>
  </div>
</template>

<style type="scss">
  .pdf-page-container {
    position: relative;
    max-width: 850px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  }

  .document-fields-container {
    position: absolute! important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }
</style>
