export default class TextMessagingButtonOpenDashboard {
  constructor ({ bus }) {
    new Vue({
      el: '#btn-open-user-text-messaging',
      methods: {
        openTextMessagesDashboard: function ({ coversationId = null }) {
          bus.$emit('open-dashboard', { coversationId })
        }
      }
    })
  }
}
