export default {
  improveWriting: {
    text: 'Improve Writing',
    iconSrc: '/assets/text-edit-iq/wand-icon.svg',
    action: 'improveWriting'
  },
  changeTone: {
    text: 'Change Tone',
    iconSrc: '/assets/text-edit-iq/mic-icon.svg',
    children: [
      { text: 'Formal', action: 'changeTone:formal' },
      { text: 'Informal', action: 'changeTone:informal' }
    ]
  },
  translate: {
    text: 'Translate to',
    iconSrc: '/assets/text-edit-iq/lang-icon.svg',
    children: [
      { text: 'English', action: 'translateTo:english' },
      { text: 'Spanish', action: 'translateTo:spanish' },
      { text: 'Chinese', action: 'translateTo:chinese' },
      { text: 'Portuguese', action: 'translateTo:portuguese' },
      { text: 'Hindi', action: 'translateTo:hindi' },
      { text: 'Vietnamese', action: 'translateTo:vietnamese' },
      { text: 'Korean', action: 'translateTo:korean' }
    ]
  },
  makeShorter: {
    text: 'Make Shorter',
    iconSrc: '/assets/text-edit-iq/two-lines-icon.svg',
    action: 'makeShorter'
  },
  makeLonger: {
    text: 'Make Longer',
    iconSrc: '/assets/text-edit-iq/four-lines-icon.svg',
    action: 'makeLonger'
  },
  summarize: {
    text: 'Summarize',
    iconSrc: '/assets/text-edit-iq/list-icon.svg',
    action: 'summarize'
  },
  suggestActionItems: {
    text: 'Suggest Action Items',
    iconSrc: '/assets/text-edit-iq/checklist-icon.svg',
    action: 'suggestActionItems'
  },
  explain: {
    text: 'Explain',
    iconSrc: '/assets/text-edit-iq/question-icon.svg',
    action: 'explain'
  }
}
