// vendor
import axios from 'axios'
import dayjs from 'dayjs'
import { debounce, get, isNil, orderBy } from 'lodash'
import { createStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
// local
import { LOGIC_OPERATORS } from './constants'
import { findFilterGroup, filterObject, filterGroupObject } from '../utils'

const setErrorMessageByPortalType = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const clientIdParam = urlParams.get('client_id')
  if (clientIdParam === null) {
    return 'Something went wrong with the requested action'
  } else {
    return 'Filters cannot be updated while previewing the HR Portal'
  }
}

const validateQueryObject = ({ state, updateFilterErrorState }) => {
  const valid = true
  const filterGroups = get(state, 'filterGroups', [])

  // validate if a colum is selected
  filterGroups.forEach(filterGroup => {
    filterGroup.filters.forEach(filter => {
      // validate when if a column is selected
      const filterLabel = get(filter, 'label')
      const filterType = get(filter, 'label.type')

      if (filterLabel === null) {
        updateFilterErrorState({ filterGroup, filter, error: { label: 'column not selected', type: 'label' } })
        toastr.warning('Non-valid filters will be ignored/not saved.')
        // valid = null
      } else {
        updateFilterErrorState({ filterGroup, filter, error: undefined })
      }
      // validate when a matcher is selected
      if (filterLabel !== null && filter.equivalency === null) {
        updateFilterErrorState({ filterGroup, filter, error: { label: 'matcher not selected', type: 'equivalency' } })
        toastr.warning('There are filters without a matcher selected. Non-valid filters will be ignored/not saved.')
        // valid = null
      } else if (filterLabel !== null && filter.equivalency !== null) {
        updateFilterErrorState({ filterGroup, filter, error: undefined })
      }

      // validate when a special matcher type is selected
      if (filterLabel !== null && filter.equivalency !== null) {
        const equivalencyValue = get(filter, 'equivalency.value')
        if (equivalencyValue !== 'is_unknown' && equivalencyValue !== 'has_any_value') {
          const inputTextValue = get(filter, 'value')
          const inputDateValue = get(filter, 'dateValue')
          const inputSelectValue = get(filter, 'selectValue')

          // validate text input and integer
          if (
            (filterType === 'string' || filterType === 'integer') &&
            (inputTextValue === null || inputTextValue === '')
          ) {
            updateFilterErrorState({ filterGroup, filter, error: { label: 'matcher not selected', type: 'equivalency' } })
            toastr.warning('There are filters without a valid value. Non-valid filters will be ignored/not saved.')
          }

          // validate datetime integer input
          if (
            (filterType === 'datetime') &&
            (equivalencyValue === 'greater_than' || equivalencyValue === 'less_than') &&
            (inputTextValue === null || inputTextValue === '')
          ) {
            updateFilterErrorState({ filterGroup, filter, error: { label: 'matcher not selected', type: 'equivalency' } })
            toastr.warning('There are filters without a valid value. Non-valid filters will be ignored/not saved.')
          }

          // validate datetime date iput in mm/dd/yyyy
          if (
            (filterType === 'datetime') &&
            (equivalencyValue !== 'greater_than' && equivalencyValue !== 'less_than') &&
            (inputDateValue === null || !dayjs(inputDateValue).isValid())
          ) {
            updateFilterErrorState({ filterGroup, filter, error: { label: 'matcher not selected', type: 'equivalency' } })
            toastr.warning('There are filters without a valid value. Non-valid filters will be ignored/not saved.')
          }

          // validate enum
          if (
            (filterType === 'enum') &&
            (equivalencyValue !== 'is_unknown' && equivalencyValue !== 'has_any_value') &&
            inputSelectValue === null
          ) {
            updateFilterErrorState({ filterGroup, filter, error: { label: 'matcher not selected', type: 'equivalency' } })
            toastr.warning('There are filters without a valid value. Non-valid filters will be ignored/not saved.')
          }
        }
      } else if (filter.label !== null && filter.equivalency !== null) {
        updateFilterErrorState({ filterGroup, filter, error: undefined })
      }
    })
  })
  return valid
}

const parseDatetime = ({ filter }) => {
  const equivalencyType = filter && filter.equivalency && filter.equivalency.value
  if (equivalencyType === 'greater_than') {
    return get(filter, 'value')
  } else if (equivalencyType === 'less_than') {
    return get(filter, 'value')
  } else {
    return filter.dateValue
  }
}

const parseEnum = ({ filter }) => {
  const value = get(filter, 'selectValue.value')
  return value
}

const parseValue = ({ filterGroup, filter }) => {
  let value

  if (get(filter, 'label.type') === 'datetime') {
    value = parseDatetime({ filterGroup, filter })
    return value
  }

  if (get(filter, 'label.type') === 'enum') {
    value = parseEnum({ filter })
    return value
  } else {
    value = get(filter, 'value')
    return value
  }
}
const createReportObject = ({ state, name }) => {
  const filterGroups = state.filterGroups
  const filterGroupsParsed = []

  filterGroups.forEach(fg => {
    const filters = []

    fg.filters.forEach(f => {
      let attr
      const matcher = get(f, 'equivalency.value')
      const val = parseValue({ filterGroup: fg, filter: f })
      // eslint-disable-next-line camelcase
      const custom_attribute_id = get(f, 'label.custom_attribute_id')
      // eslint-disable-next-line camelcase
      if (custom_attribute_id === undefined) {
        attr = get(f, 'label.value')
      }

      if (f.error === undefined) {
        filters.push({
          matcher,
          val,
          attr,
          // eslint-disable-next-line camelcase
          custom_attribute_id
        })
      }
    })

    if (filters.length !== 0) {
      filterGroupsParsed.push({
        match_all_filters: fg.logicalOperator.value,
        filters_attributes: filters
      })
    }
  })

  const obj = {
    custom_reports: {
      match_all_filters: state.mainLogicalOperator.value,
      filter_groups_attributes: filterGroupsParsed
    }
  }

  if (name !== undefined) {
    obj.name = name
  }

  return obj
}

const store = createStore({
  state () {
    return {
      isNew: false,
      requestStatus: null, // started, loading, success, error
      filterGroups: [],
      baseReportsUrl: '/custom_reports',
      mainLogicalOperator: {
        name: 'And',
        value: 'logic_and'
      }
    }
  },
  mutations: {
    isNew (state, isNew) {
      state.isNew = isNew
    },
    isOwner (state, isOwner) {
      state.isOwner = isOwner
    },
    isHrPortal (state, isHrPortal) {
      state.isHrPortal = isHrPortal
    },
    setEnumsCollections (state, { enums }) {
      if (enums === null) { return }
      state.enumsCollections = JSON.parse(enums)
    },
    updateBaseUrl (state, { url }) {
      if (!isNil) { return null }
      state.baseReportsUrl = url
    },
    newFilterGroup (state) {
      const filterGroup = filterGroupObject()
      state.filterGroups.push(filterGroup)
    },
    removeFilterGroup (state, filterGroup) {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      state.filterGroups.splice(filterGroupIndex, 1)
    },
    newFilter (state, filterGroup) {
      const filterIndex = findFilterGroup({ state, filterGroup })
      state.filterGroups[filterIndex].filters.push(filterObject())
    },
    removeFilter (state, { filterGroup, filter }) {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      const filterIndex = state.filterGroups[filterGroupIndex].filters.findIndex(f => f.id === filter.id)
      state.filterGroups[filterGroupIndex].filters.splice(filterIndex, 1)
    },
    setEquivalencyOptions (state, { filterGroup, filter }) {
      const options = state.conditionOperators.find(eo => eo.type === filter.label.type).options

      state.filterGroups
        .find(fg => fg.id === filterGroup.id).filters
        .find(f => f.id === filter.id).equivalencyOptions = options
    },
    setFilterInputVisibility (state, { filterGroup, filter, hideInputText }) {
      state.filterGroups
        .find(fg => fg.id === filterGroup.id).filters
        .find(f => f.id === filter.id).hideInputText = hideInputText
    },
    setFilterError (state, { filterGroup, filter, error }) {
      state.filterGroups
        .find(fg => fg.id === filterGroup.id).filters
        .find(f => f.id === filter.id).error = error
    },
    resetFilter (state, { filterGroup, filter }) {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      const filterIndex = state.filterGroups[filterGroupIndex].filters.findIndex(f => f.id === filter.id)
      state.filterGroups[filterGroupIndex].filters[filterIndex].equivalency = null
      state.filterGroups[filterGroupIndex].filters[filterIndex].value = null
      state.filterGroups[filterGroupIndex].filters[filterIndex].selectValue = null
      state.filterGroups[filterGroupIndex].filters[filterIndex].dateValue = null
    },
    resetAllFilterValues (state, { filterGroup, filter }) {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      const filterIndex = state.filterGroups[filterGroupIndex].filters.findIndex(f => f.id === filter.id)
      state.filterGroups[filterGroupIndex].filters[filterIndex].value = null
      state.filterGroups[filterGroupIndex].filters[filterIndex].selectValue = null
      state.filterGroups[filterGroupIndex].filters[filterIndex].dateValue = null
    },
    resetInputTextFilterValue (state, { filterGroup, filter }) {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      const filterIndex = state.filterGroups[filterGroupIndex].filters.findIndex(f => f.id === filter.id)
      state.filterGroups[filterGroupIndex].filters[filterIndex].value = null
    },
    updateMainLogicalOperator (state, { value }) {
      state.mainLogicalOperator = value
    },
    updateFilterGroupLogicalOperator (state, { filterGroup, value }) {
      state.filterGroups.find(f => f.id === filterGroup.id).logicalOperator = value
    },
    updateFilterLabel (state, { filterGroup, filter, value }) {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      state.filterGroups[filterGroupIndex].filters.find(f => f.id === filter.id).label = value
    },
    updateFilterEquivalency (state, { filterGroup, filter, value }) {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      state.filterGroups[filterGroupIndex].filters.find(f => f.id === filter.id).equivalency = value
    },
    updateFilterValue (state, { filterGroup, filter, value }) {
      state.filterGroups
        .find(fg => fg.id === filterGroup.id).filters
        .find(f => f.id === filter.id).value = value
    },
    updateFilterSelectValue (state, { filterGroup, filter, selectValue }) {
      state.filterGroups
        .find(fg => fg.id === filterGroup.id).filters
        .find(f => f.id === filter.id).selectValue = selectValue
    },
    updateFilterDateValue (state, { filterGroup, filter, dateValue }) {
      console.log('dateValue:', dateValue)
      state.filterGroups
        .find(fg => fg.id === filterGroup.id).filters
        .find(f => f.id === filter.id).dateValue = dateValue
    },
    setFilterableColumns (state, { columns }) {
      if (columns === null || columns === undefined) {
        state.columns = []
      } else {
        const parsedColums = JSON.parse(columns)
        const results = []

        for (const columnKey in parsedColums) {
          const obj = {
            name: parsedColums[columnKey].label,
            value: parsedColums[columnKey].name,
            type: parsedColums[columnKey].type,
            custom_attribute_id: parsedColums[columnKey].custom_attribute_id
          }
          // logic for custom attributes
          if (parsedColums[columnKey].custom_attribute_id !== undefined) {
            obj.custom_attribute_id = parsedColums[columnKey].custom_attribute_id
            obj.value = parsedColums[columnKey].label
          }
          if (parsedColums[columnKey].type !== undefined) {
            results.push(obj)
          }
        }

        state.filterableColumns = orderBy(results, ['name'], ['asc'])
      }
    },
    setconditionOperators (state, { conditionOperators }) {
      if (conditionOperators === null || conditionOperators === undefined) {
        state.conditionOperators = []
      } else {
        const parsedConditionOperators = JSON.parse(conditionOperators)
        const results = parsedConditionOperators.map(pco => {
          const hideAll = pco.hide_all
          const options = pco.options.map(o => {
            return {
              name: o.name,
              value: o.value,
              endLabel: o.end_label,
              hideInputText: o.hide || hideAll,
              hideInputDate: o.hide_date
            }
          })
          return {
            type: pco.type,
            options

          }
        })

        state.conditionOperators = results
      }
    },
    setNewStore (state, { newStore }) {
      state.new = true
      state.customReportId = newStore.id
      state.mainLogicalOperator = LOGIC_OPERATORS.find(lo => lo.value === newStore.match_all_filters)
      const filterGroups = get(newStore, 'filter_groups', []).map(fg => {
        const logicalOperator = LOGIC_OPERATORS.find(lo => lo.value === fg.match_all_filters)
        const filters = fg.filters.map(f => {
          let label
          if (f.additional_data.custom_attribute_id !== undefined) {
            label = state.filterableColumns.find(ca => ca.custom_attribute_id === Number(f.additional_data.custom_attribute_id))
          } else {
            label = state.filterableColumns.find(fc => fc.value === f.additional_data.attr)
          }

          const equivalencyOption = state.conditionOperators.find(eo => eo.type === label.type)
          const equivalency = equivalencyOption.options.find(e => e.value === f.matcher)
          const value = get(f, 'additional_data.val', null)
          let selectValue
          let dateValue

          if (label.type === 'enum') {
            let collection
            if (label.custom_attribute_id !== undefined) {
              collection = state.enumsCollections[label.custom_attribute_id]
              selectValue = collection.find(sv => sv.value === String(value))
            } else {
              collection = state.enumsCollections[label.value]
              selectValue = collection.find(sv => sv.value === Number(value))
            }
          }

          if (label.type === 'datetime') {
            dateValue = get(f, 'additional_data.val', null)
          }

          return {
            initialized: true,
            label,
            equivalencyOption: equivalencyOption.options,
            equivalency,
            hideInputText: equivalency && equivalency.hideInputText,
            value,
            selectValue,
            dateValue,
            id: uuidv4(),
            type: 'singleFilterType'
          }
        })
        return {
          id: uuidv4(),
          logicalOperator,
          type: 'filterGroupType',
          filters
        }
      })
      state.filterGroups = filterGroups
    },
    updateRequestStatus (state, { status }) {
      state.requestStatus = status
    }
  },
  getters: {
    enumsCollections: (state) => () => {
      return state.enumsCollections
    },
    baseUrl: (state) => () => {
      return state.baseReportsUrl
    },
    conditionOperators: (state) => () => {
      return state.conditionOperators
    },
    filterGroupFilters: (state) => (filterGroup) => {
      return state.filterGroups.find(fg => fg.id === filterGroup.id).filters
    },
    filterEquivalencyOptions: (state) => (filter) => {
      if (!filter || (filter && filter.label == null)) { return null }

      const equivalencyObject = state.conditionOperators.find((eo) => {
        return eo.type === filter.label.type
      })

      return equivalencyObject && equivalencyObject.options
    },
    filterGroups (state) {
      return state.filterGroups
    },
    mainLogicalOperator (state) {
      return state.mainLogicalOperator
    },
    filterGroupLogicalOperator: (state) => (filterGroup) => {
      return state.filterGroups.find(fg => fg.id === filterGroup.id).logicalOperator
    },
    filterLabel: (state) => (filterGroup, filter) => {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      return state.filterGroups[filterGroupIndex].filters.find(f => f.id === filter.id).label
    },
    filterEquivalency: (state) => (filterGroup, filter) => {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      return state.filterGroups[filterGroupIndex].filters.find(f => f.id === filter.id).equivalency
    },
    filterValue: (state) => (filterGroup, filter) => {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      return state.filterGroups[filterGroupIndex].filters.find(f => f.id === filter.id).value
    },
    filterSelectValue: (state) => (filterGroup, filter) => {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      return state.filterGroups[filterGroupIndex].filters.find(f => f.id === filter.id).selectValue
    },
    filterDateValue: (state) => (filterGroup, filter) => {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      return state.filterGroups[filterGroupIndex].filters.find(f => f.id === filter.id).dateValue
    },
    filterDateEndLabel: (state) => (filterGroup, filter) => {
      const filterGroupIndex = findFilterGroup({ state, filterGroup })
      const dateEndLabel = state.filterGroups[filterGroupIndex].filters.find(f => f.id === filter.id).dateValue
      console.log(dateEndLabel)

      const equivalencyValue = filter.equivalency.value
      const option = state.conditionOperators.find(eo => eo.type === filter.label.type).options.find(o => o.value === equivalencyValue)
      return option && option.endLabel
    }
  },
  actions: {
    updateSpinnerUI ({ commit, dispatch }, { status }) {
      if (status === 'loading') {
        $('#custom-report-table').addClass('custom-report-table--loading')
        $('.card-results-container').addClass('custom-report-table--loading')
      }

      if (status === 'done') {
        $('#custom-report-table').removeClass('custom-report-table--loading')
        $('.card-results-container').removeClass('custom-report-table--loading')
      }
    },
    updateEquivalency ({ commit, dispatch }, { filterGroup, filter, value }) {
      const hideInputText = value && value.hideInputText
      commit('updateFilterEquivalency', { filter, filterGroup, value })
      commit('setFilterInputVisibility', { filter, filterGroup, hideInputText })
      dispatch('updateAjaxQueryOnUiUpdate')
    },
    updateMainLogicalOperatorAction ({ commit }, value) {
      commit('updateMainLogicalOperator', value)
    },
    setNewStoreAction ({ commit }, newStore) {
      commit('setNewStore', newStore)
    },
    setFilterableColumnsAction ({ commit }, columns) {
      commit('setFilterableColumns', columns)
    },
    setconditionOperatorsAction ({ commit }, conditionOperators) {
      commit('setconditionOperators', conditionOperators)
    },
    updateAjaxQueryOnUiUpdate ({ dispatch }) {
      // dispatch('debounceAjaxUpdateFilter')
    },
    debounceAjaxUpdateFilter: debounce(({ dispatch }, type) => {
      dispatch('ajaxUpdateFilter', type)
    }, 3000),
    async ajaxUpdateFilter ({ state, commit, dispatch }, type) {
      if (state.requestStatus === 'loading') { return }

      dispatch('updateSpinnerUI', { status: 'loading' })
      commit('updateRequestStatus', { status: 'loading' })
      const updateFilterErrorState = ({ filterGroup, filter, error }) => {
        commit('setFilterError', { filterGroup, filter, error })
      }
      // validate logic
      const isValid = validateQueryObject({ state, updateFilterErrorState })
      const obj = createReportObject({ state })
      if (isValid === null) { return null }

      if (type && type === 'save') {
        dispatch('renderShowTable', obj)
      } else {
        dispatch('renderNewTable', obj)
      }
    },
    async renderShowTable ({ state, dispatch, commit, getters }, obj) {
      try {
        const url = `${getters.baseUrl()}`
        await axios.put(url, obj)
        const csrfToken = document.getElementsByName('csrf-token')[0].content
        const resp = await axios.get(`${state.baseReportsUrl}`, {
          responseType: 'text',
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'text/javascript',
            Accept: 'text/javascript'
          }
        })
        // eslint-disable-next-line no-eval
        eval(resp.data)
        commit('updateRequestStatus', { status: 'success' })
        toastr.success('Report saved successfuly')
        dispatch('updateSpinnerUI', { status: 'done' })
      } catch (err) {
        // Handle Error Here
        console.error(err)
        commit('updateRequestStatus', { status: 'error' })
        dispatch('updateSpinnerUI', { status: 'done' })
        toastr.error('Something went wrong with the requested action')
      }
    },
    async renderNewTable ({ getters, commit, dispatch }, obj) {
      const params = JSON.stringify(obj.custom_reports)
      const csrfToken = document.getElementsByName('csrf-token')[0].content
      const url = `${getters.baseUrl()}?filter_groups=${params}`
      commit('updateRequestStatus', { status: 'started' })

      try {
        commit('updateRequestStatus', { status: 'loading' })
        const resp = await axios.get(url, {
          responseType: 'text',
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'text/javascript',
            Accept: 'text/javascript'
          }
        })
        // eslint-disable-next-line no-eval
        eval(resp.data)
        commit('updateRequestStatus', { status: 'success' })
        dispatch('updateSpinnerUI', { status: 'done' })
        // toastr.success('Updated successfuly')
      } catch (err) {
        // Handle Error Here
        console.error(err)
        commit('updateRequestStatus', { status: 'error' })
        dispatch('updateSpinnerUI', { status: 'done' })
        const errorMessage = setErrorMessageByPortalType()
        toastr.error(errorMessage)
      }
    },
    async ajaxCreateCustomReport ({ state, commit, getters }, { name }) {
      commit('updateRequestStatus', { status: 'started' })

      const obj = createReportObject({ state, name })

      try {
        commit('updateRequestStatus', { status: 'success' })
        const resp = await axios.post(`${getters.baseReportsUrl()}`, obj)
        console.log('created')
        console.log(resp.data)
        window.location.href = `${getters.baseReportsUrl()}/${resp.data.id}`
      } catch (err) {
        commit('updateRequestStatus', { status: 'error' })
        console.error(err)
      }
    }
  }
})

export default store
