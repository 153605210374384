<script>
import pdf from 'vue-pdf'
import PdfPage from './PdfPage'
import { mapGetters } from 'vuex'
import SignatureInput from './SignatureInput'
import axios from 'axios'

export default {
  name: 'ESignature',
  components: {
    SignatureInput,
    PdfPage
  },
  props: ['signatureDocument'],
  data() {
    return {
      mainFieldType: 'SignatureField',
      pdfSrc: pdf.createLoadingTask(this.signatureDocument.document.url),
      numPages: 0
    }
  },
  mounted() {
    this.loadPdf()
    this.loadDocumentSigners()
  },
  methods: {
    loadPdf() {
      this.pdfSrc.promise.then(pdf => {
        this.numPages = pdf.numPages
      })
    },
    loadDocumentSigners() {
      this.$store.dispatch('ajaxLoadSigners', this.signatureDocument.id)
    },
    handlePdfPageLoaded () {
      if(this.pagesLoaded == this.numPages) {
        this.initPdf()
        /*
          The vue-pdf doesn't support disabling the annotation layer. Issue related #151
          While vue-pdf support it we remove annotation layer.
        */
        document.querySelectorAll(".annotationLayer").forEach(el => el.remove())
      }
    },
    async initPdf () {
      if(this.signatureDocument.id == "") return
      const fields = await axios.get(`/signature_documents/${this.signatureDocument.id}/document_fields`)

      for(const field of fields.data) {
        this.$store.commit('newField', {
          _id: field.id,
          type: field.type,
          pageIndex: field.page_index,
          percentWidth: parseFloat(field.percent_width),
          percentHeight: parseFloat(field.percent_height),
          percentX: parseFloat(field.percent_x),
          percentY: parseFloat(field.percent_y),
          signature: {
            id: field.document_signer.id,
            name: field.document_signer.name,
            email: field.document_signer.email,
            phoneNumber: field.document_signer.phone_number,
            userId: field.document_signer.user_id
          }
        })
      }
    },
    async save (e) {
      e.preventDefault()
      if(!this.validateAllFieldsHaveSigner()) {
        alert('Please ensure all signature fields have an assigned Contact or Firm User.')
        return
      } else if(!this.validateLeastOneSignatureAddedBySigner()) {
        alert('Please ensure for each signer at least one signature or initial  has been added to the document.')
        return
      }

      this.update()
    },
    validateAllFieldsHaveSigner() {
      const fieldsList = Object.entries(this.fields)
      let fieldAssignedCount = 0

      for (const [fieldId, field] of fieldsList) {
        if(Object.keys(field.signature).length > 0) fieldAssignedCount++
      }

      return fieldsList.length == fieldAssignedCount
    },
    validateLeastOneSignatureAddedBySigner() {
      const fieldsList = Object.entries(this.fields)

      for (const signer of this.signers) {
        let signerHasLeastOneSignatureFieldAssigned = false
        for (const [fieldId, field] of fieldsList) {
          if((field.type == 'SignatureField' || field.type == 'InitialsField') && signer.id == field.signature.id ) {
            signerHasLeastOneSignatureFieldAssigned = true
            break
          }
        }
        if(!signerHasLeastOneSignatureFieldAssigned) return false
      }

      return true
    },
    async update () {
      let data = {
        fields: []
      }

      for (const [fieldId, field] of Object.entries(this.fields)) {
        data.fields.push(
          {
            id: field._id,
            type: field.type,
            page_index: field.pageIndex,
            percent_x: field.percentX,
            percent_y: field.percentY,
            percent_width: field.percentWidth,
            percent_height: field.percentHeight,
            signature: {
              id: field.signature.id
            }
          }
        )
      }

      const res = await axios.put(`/signature_documents/${this.signatureDocument.id}`, data)
      window.location.href = `/documents?signature_request_for=${this.signatureDocument.id}`
    },
    async removeField (field) {
      this.$store.commit('removeField', field.id)
      if(field._id != null) {
        axios.delete(`/signature_documents/${this.signatureDocument.id}/document_fields/${field._id}`)
      }
    },
    close (e) {
      e.preventDefault()
      window.location.href = '/documents'
    }
  },
  computed: {
    ...mapGetters(['fields', 'signers', 'fieldSelected', 'pagesLoaded'])
  }
}
</script>

<template>
  <div class="sign-preparation-container">

    <div class="sign-preparation-l-column">
      <div class="sign-preparation-l-column-toolbar">

        <div class="switch-esign-field">
          <input type="radio" name="SignatureField" id="SignatureField" value="SignatureField" v-model="mainFieldType">
          <label for="SignatureField">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1666117839/e-signature/signature-icon_ujo2x9.svg" />
            Signature
          </label>
          <br>
          <input type="radio" name="InitialsField" id="InitialsField" value="InitialsField" v-model="mainFieldType">
          <label for="InitialsField">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1666126838/e-signature/initials-icon_xq4ms5.svg" />
            Initials
          </label>
          <br>
          <input type="radio" name="DateField" id="DateField" value="DateField" v-model="mainFieldType">
          <label for="DateField">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1666126995/e-signature/calendar-icon_iyx1mr.svg" />
            Date Field
          </label>
          <br>
          <input type="radio" name="TextField" id="TextField" value="TextField" v-model="mainFieldType">
          <label for="TextField">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1666127351/e-signature/text-icon_s7fzcg.svg" />
            Text Field
          </label>
        </div>

        <div>
          <button class="btn esign-cancel-btn" @click="close">Cancel</button>
          <button class="btn esign-continue-btn" type="button" @click="save">
            Save & Continue
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1666128168/e-signature/continue-arrow_s7ijfy.svg" />
          </button>
        </div>
      </div>

      <div class="esign-top-blue-information-msg">
        Select a field type and click anywhere to place it on your document.
      </div>

      <pdf-page v-for="pageIndex in numPages" :src="pdfSrc" :page-index="pageIndex" :insert-field-type="mainFieldType" @pdf-page-loaded="handlePdfPageLoaded"/>
    </div>

    <div class="sign-preparation-r-column">
      <div v-for="(field, index) in fields" :key="index">
        <div v-if="field.id == fieldSelected" class="esign-field-details-container esign-details-fixed-position">
          <div class="esign-field-remove-link" @click="removeField(field)">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1666204722/e-signature/trash-can-icon_brrs66.svg" />
          </div>
          <div class="esign-field-details-title">Field Details</div>
          <div class="esign-field-details-title-separator"></div>
          <label class="esign-field-details-label" for="field-type">Type</label>
          <div v-if="field.type == 'SignatureField'">
            <div class="esign-field-details-type">
              <img src="https://res.cloudinary.com/docketwise/image/upload/v1666117839/e-signature/signature-icon_ujo2x9.svg" />
              Signature
            </div>
          </div>
          <div v-if="field.type == 'InitialsField'">
            <div class="esign-field-details-type">
              <img src="https://res.cloudinary.com/docketwise/image/upload/v1666126838/e-signature/initials-icon_xq4ms5.svg" />
              Initials
            </div>
          </div>
          <div v-if="field.type == 'DateField'">
            <div class="esign-field-details-type">
              <img src="https://res.cloudinary.com/docketwise/image/upload/v1666126995/e-signature/calendar-icon_iyx1mr.svg" />
              Date Field
            </div>
          </div>
          <div v-if="field.type == 'TextField'">
            <div class="esign-field-details-type">
              <img src="https://res.cloudinary.com/docketwise/image/upload/v1666127351/e-signature/text-icon_s7fzcg.svg" />
              Text Field
            </div>
          </div>
          <signature-input :field="field"/>
        </div>
      </div>

      <div v-if="fieldSelected == null" class="esign-field-details-container esign-details-fixed-position">
        <div class="esign-field-details-title">Field Details</div>
        <div class="esign-field-details-title-separator"></div>
        <div class="esign-field-not-selected-message">No field selected.</div>
      </div>
    </div>

  </div>
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

  .sign-preparation-container {
    display: flex;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    margin-top: 57px;
  }
  .sign-preparation-l-column {
    width: 100%;
    position: relative;
    padding-bottom: 40px;
  }
  .sign-preparation-l-column-toolbar {
    display: flex;
    justify-content: space-between;
    background: rgba(250, 250, 250, 1);
    padding: 15px 40px 12px 40px;
    border-bottom: 1px solid #ECECEC;
    position: fixed;
    z-index: 2;
  }

  #main.expanded-sidebar > div > div > div > div > div > .sign-preparation-l-column-toolbar {
    width: calc(100% - 256px);
  }

  #main.collapsed-sidebar > div > div > div > div > div > .sign-preparation-l-column-toolbar {
    width: calc(100% - 104px);
  }

  .esign-top-blue-information-msg {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 86px;
    padding: 11px 15px 11px 15px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: rgba(21, 58, 95, 0.6);
    background-color: #e0f3ff;
    border: 1px solid #CEECFF;
    border-radius: 7px;
  }
  .sign-preparation-r-column {
    flex: 0 0 300px;
    background-color: white;
    border-left: 1px solid #ECECEC;
    padding: 90px 24px 20px 24px;
  }
  .grey-section-esign {
    background-color: #fafafa;
  }
  .esign-continue-btn {
    background-color: #1976D2;
    color: #FFFFFF;
    font-weight: 600;
    padding: 8px 16px 9px 16px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    &:hover {
      color: #c1d7ec;
    }
    &:active {
      color: #FFFFFF;
    }
    &:focus {
      color: #FFFFFF;
    }
    img {
      margin-left: 8px;
    }
  }
  .esign-cancel-btn {
    background-color: transparent;
    border: none;
    color: #B3B3B3;
    margin-right: 16px;
  }

  /* START - TURN RADIO OPTIONS INTO A SWITCH */
  .switch-esign-field {
    display: flex;
    overflow: hidden;
  }
  .switch-esign-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }
  .switch-esign-field label {
    background-color: white;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    padding: 8px 12px;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in-out;
    img {
      margin-right: 7px;
    }
  }
  .switch-esign-field label:hover {
    cursor: pointer;
  }
  .switch-esign-field input:checked + label {
    background-color: #F0F5FB;
  }
  .switch-esign-field label:first-of-type {
    border-radius: 10px 0 0 10px;
    border-left: 1px solid #E0E0E0;
  }
  .switch-esign-field label:last-of-type {
    border-radius: 0 10px 10px 0;
    border-right: 1px solid #E0E0E0;
  }
  /* END - TURN RADIO OPTIONS INTO A SWITCH */

  .esign-field-user-not-assigned {
    background-color: #FCF2CD;
    border: 3px solid #FFCA28;
    color: #000;
    font-family: 'Indie Flower', cursive;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 16px;
    padding: 4px 8px;
  }
  .esign-field-user-assigned {
    background-color: #E0FFF4;
    border: 3px solid #34D39D;
    color: #000;
    font-family: 'Indie Flower', cursive;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 16px;
    padding: 4px 8px;
  }
  .esign-field-active-state{
    background-color: #F0F5FB;
    border: 3px solid #1976D2;
    .handle {
      border: 1px solid #1976D2;
      background-color: #F0F5FB;
    }
    .handle-tl {
      top: -13px;
      left: -13px;
    }
    .handle-tm {
      top: -13px;
    }
    .handle-tr {
      top: -13px;
      right: -13px;
    }
    .handle-mr {
      right: -13px;
    }
    .handle-br {
      bottom: -13px;
      right: -13px;
    }
    .handle-bm {
      bottom: -13px;
    }
    .handle-bl {
      bottom: -13px;
      left: -13px;
    }
    .handle-ml {
      left: -13px;
    }
  }

  .esign-field-details-container {
    position: relative;
    margin-bottom: 36px;
  }
  .esign-field-details-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 16px;
  }
  .esign-field-details-title-separator {
    width: 35px;
    height: 3px;
    background-color: #1976D2;
  }
  .esign-field-remove-link {
    position: absolute;
    right: 0;
    cursor: pointer !important;
  }
  .esign-field-details-label {
    color: #000;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .esign-field-details-type {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    img {
      margin-right: 7px;
    }
  }
  .esign-field-not-selected-message {
    color: #000;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .esign-details-fixed-position {
    position: fixed;
    width: 244px;
  }

  .navbar-fixed-esign {
    
  }
</style>
