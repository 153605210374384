<script>
import axios from 'axios'
import vueDropzone from 'vue2-dropzone/dist/vue2Dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import baseModal from './base-modal.vue'

export default {
  name: 'contact-smart-document-request',
  components: {
    vueDropzone,
    baseModal
  },
  props: ['smartDocumentRequestItem'],
  data () {
    return {
      smartDocumentRequest: this.smartDocumentRequestItem,
      instructionsBoxEnabled: false,
      dropzoneOptions: {
        url: `/smart_document_requests/${this.smartDocumentRequestItem.id}/smart_documents`,
        createImageThumbnails: false,
        parallelUploads: 0,
        maxFiles: 1,
        maxFilesize: 100,
        acceptedFiles: 'image/*,application/pdf',
        autoProcessQueue: true,
        timeout: 99999999,
        dictDefaultMessage: "<img src='https://res.cloudinary.com/docketwise/image/upload/v1730240580/app-icons/sf-upload-icon_ip7aul.svg'/><div class='dw-sf-dropzone-internal-title'>Drag and drop or <span>choose file</span> to upload.</div><div class='dw-sf-dropzone-internal-formats'>JPG, JPEG, PNG or PDF</div>"
      },
      awss3: {
        signingURL: '/documents/s3_presigned_url'
      }
    }
  },
  methods: {
    modifyFormData (file, xhr, formData) {
      const authenticityTokenValue = $('#documents_authenticity_token').val()
      formData.append('authenticity_token', authenticityTokenValue)
      const renamedFileName = $(file.previewElement).find('.dz-filename span').html()
      formData.append('smart_document[title]', renamedFileName)
      formData.append('smart_document[size]', file.size)
      formData.append('smart_document[url]', file.s3ObjectLocation)
    },
    s3UploadError (errorMessage) {
      toastr.error(errorMessage)
    },
    successCallback (_, response) {
      if (response.id) {
        this.smartDocumentRequest.smart_document = response
        this.$refs.smartDocumentUploader.removeAllFiles()
        toastr.success('Document uploaded successfully.')
      }
    },
    errorCallback (file, message, xhr) {
      const errorMessage = `Sorry, something went wrong when uploading ${file.name}. Please try again later.`
      toastr.error(errorMessage)
    },
    async deleteSmartDocument () {
      if (!confirm('Are you sure you want to delete this document?')) return (null)
      try {
        const url = `/smart_document_requests/${this.smartDocumentRequest.id}/smart_documents/${this.smartDocumentRequest.smart_document.id}`
        await axios.delete(url)
        this.smartDocumentRequest.smart_document = null
        toastr.success('Document successfully removed')
      } catch (e) {
        console.log(e)
        toastr.error('Something went wrong')
      }
    },
    reviewSmartDocument () {
      if (!this.smartDocumentRequest.smart_document.data) return (null)
      $(`#smart-document-modal-${this.smartDocumentRequest.smart_document.id}`).modal('show')
    },
    toggleInstructionsBox () {
      this.instructionsBoxEnabled = !this.instructionsBoxEnabled
    },
    humanize (str) {
      return str.toUpperCase().replace(/^[\s_]+|[\s_]+$/g, '').replace(/[_\s]+/g, ' ')
    }
  }
}
</script>

<template>
  <div class="row question-and-comment-container">
    <div class="col-sm-7">
      <div class="question-container">
        <p>
          <b>Smart Document -</b>
          {{ humanize(smartDocumentRequest.smart_document_type) }}
        </p>

        <div v-if="smartDocumentRequest.smart_document">
          <div class="dw-sf-uploaded-file-container">
            <div class="dw-sf-uploaded-file-left">
              <img src="https://res.cloudinary.com/docketwise/image/upload/v1730306786/app-icons/file-icon_umxlu7.svg">
            </div>
            <div class="dw-sf-uploaded-file-center">
              <div class="dw-sf-uploaded-file-name">
                {{ smartDocumentRequest.smart_document.title }}
              </div>
              <div class="dw-sf-uploaded-file-details">
                {{ `${smartDocumentRequest.smart_document.size/1000}KB` }} <span>•</span> {{ smartDocumentRequest.smart_document.created_at }}
              </div>
            </div>
            <div class="dw-sf-uploaded-file-right">
              <div
                class="dw-sf-uploaded-file-remove-icon"
                @click="deleteSmartDocument"
              >
                <img src="https://res.cloudinary.com/docketwise/image/upload/v1730307432/app-icons/close-x-icon_yso0mp.svg">
              </div>
              <div
                class="dw-sf-uploaded-file-review-edit"
                @click="reviewSmartDocument"
              >
                <a
                  href="#"
                  :disabled="!smartDocumentRequest.smart_document.data"
                >
                  Review & Edit
                </a>
              </div>
            </div>
          </div>
        </div>
        <vue-dropzone
          v-else
          id="smart-document-uploader"
          ref="smartDocumentUploader"
          class="dw-sf-dropzone"
          :options="dropzoneOptions"
          :awss3="awss3"
          @vdropzone-sending="modifyFormData"
          @vdropzone-s3-upload-error="s3UploadError"
          @vdropzone-success="successCallback"
          @vdropzone-error="errorCallback"
        />

        <div
          class="dw-sf-documents-dos-donts-container"
          @click="toggleInstructionsBox"
        >
          <div class="dw-sf-documents-dos-donts-left">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1730467248/app-icons/info-icon_yub21b.svg">
          </div>
          <div class="dw-sf-documents-dos-donts-center">
            Do’s and Don’ts
          </div>
          <div class="dw-sf-documents-dos-donts-right">
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1730467248/app-icons/caret-icon_f8w1vh.svg">
          </div>
        </div>
        <div
          v-if="instructionsBoxEnabled"
          class="dw-sf-documents-dos-donts-txt-container"
        >
          <div class="dw-sf-documents-dos-donts-txt-title dw-do-txt-color">
            Do:
          </div>
          <ul class="dw-sf-documents-dos-donts-txt-do-ul">
            <li>Ensure the image is high-resolution and in focus.</li>
            <li>Lay the document flat on a surface with no background objects.</li>
            <li>Capture the entire document, with no cut-off edges.</li>
            <li>Save the file as a PDF, PNG, or JPEG.</li>
          </ul>
          <div class="dw-sf-documents-dos-donts-txt-title dw-dont-txt-color">
            Don't:
          </div>
          <ul class="dw-sf-documents-dos-donts-txt-dont-ul">
            <li>Don't upload blurry, low-resolution, or angled images.</li>
            <li>Don't include multiple page PDF documents.</li>
            <li>Don't edit or alter the image in any way.</li>
            <li>Don't include any unnecessary background in the image.</li>
          </ul>
        </div>
      </div>
    </div>

    <base-modal
      v-if="smartDocumentRequest.smart_document"
      :modal-id="`smart-document-modal-${smartDocumentRequest.smart_document.id}`"
      title="Review & Import"
      @submit="handleSubmit"
    >
      <template #body>
        <div
          v-for="(_, field_key) in smartDocumentRequest.smart_document.data"
          :key="field_key"
          class="form-group"
        >
          <label for="smart_document_data">{{ field_key }}</label>
          <input
            id="smart_document_data"
            v-model="smartDocumentRequest.smart_document.data[field_key]"
            class="form-control"
          >
        </div>
      </template>

      <template #footer>
        <button
          class="btn btn-green full-width"
        >
          CONFIRM & IMPORT
        </button>
      </template>
    </base-modal>
  </div>
</template>
