<script>
import './overdue-balance-bar.scss'
export default {
  props: {
    message: {
      default: 'Overdue Balance',
      type: String
    }
  }
}
</script>
<template>
  <div>
    <p>
      {{ message }}
      <slot />
    </p>
  </div>
</template>
