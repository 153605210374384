<script>
import './custom-tabs.scss'
export default {
  props: ['tabsObj', 'active', 'blockInvoicesAccess', 'bankAccountsAccess', 'tabsStyle'],
  data: function () {
    return {
      computedTabs: []
    }
  },
  mounted: function () {
    this.computedTabs = this.removeDuplicates(JSON.parse(this.tabsObj))
  },
  methods: {
    removeDuplicates: function (arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index)
    },
    activeTabClass: function (tab) {
      return tab.name === this.active
    },
    disableLink: function (tabName) {
      if (tabName === 'Invoices' && this.blockInvoicesAccess === 'true') {
        return 'true'
      } else if (tabName === 'Bank Accounts' && this.bankAccountsAccess !== 'true') {
        return 'true'
      }
      return null
    }
  }
}
</script>
<template>
  <ul :class="{'underline-style': tabsStyle === 'underline'}">
    <li
      v-for="(tab) in computedTabs"
      :key="tab"
      :class="{ active: activeTabClass(tab), 'disabled': disableLink(tab.name) }"
    >
      <a
        :href="tab.url"
        :disabled="disableLink(tab.name)"
      >
        {{ tab.name }}
      </a>
    </li>
  </ul>
</template>
