<script>
import './select2-matter.scss'
export default {
  name: 'select2-matter',
  props: {
    name: {
      type: String,
      required: true
    },
    fieldId: {
      type: String,
      required: true
    },
    multiple: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Type or select matter'
    },
    queryParameters: {
      type: String,
      default: null
    },
    upfrontSelection: {
      type: Boolean,
      default: false
    },
    includeArchived: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      disabled: false,
      clientId: null,
      matterIds: null
    }
  },
  mounted () {
    this.initQueryParameters()
    if (this.upfrontSelection) this.updateMattersListListener()
    if (this.upfrontSelection) this.broadcastClientIdOnSelectMatter()
    this.configureSelect2(this.fieldId, this.placeholder, this.queryParameters)
    // the data is filled into select in app/assets/javascripts/note_builder.coffee:58
  },
  methods: {
    initQueryParameters () {
      const queryParameters = JSON.parse(this.queryParameters)
      this.clientId = queryParameters.client_id
      this.matterIds = queryParameters.matters_ids
    },
    updateMattersListListener () {
      const _self = this
      const bc = new BroadcastChannel('update_select2_client_id_channel')

      // when a client is selected, a new event is broadcasted on the channel thus
      // the client ID will be updated, and matters will be scoped to that client
      bc.onmessage = (event) => {
        _self.clientId = event.data
        $(`#${this.fieldId}`).empty().trigger('change')
        this.configureSelect2(this.fieldId, this.placeholder, this.queryParameters)
      }
    },
    broadcastClientIdOnSelectMatter () {
      const bcMatter = new BroadcastChannel('update_select2_matter_id_channel')
      const field = $(`#${this.fieldId}`)

      field.on('select2:select', function (event) {
        const matterClientId = event.params.data.client_id
        bcMatter.postMessage(matterClientId)
      })
    },
    configureSelect2 (id, placeholder) {
      $(`#${id}`).select2({
        ajax: {
          url: '/matters',
          dataType: 'json',
          delay: 250,
          data: this.processData,
          processResults: this.processResults,
          cache: true
        },
        placeholder,
        allowClear: true
      })
    },
    processResults (data) {
      const matters = data.map(function (r) {
        return {
          id: r.id,
          text: r.title,
          client_id: r.client_id
        }
      })

      return {
        results: matters
      }
    },
    processData (params) {
      const dataObj = { q: params.term }
      if (this.includeArchived) dataObj.filter = 'all'
      if (this.clientId) dataObj.client_id = this.clientId
      if (this.matterIds) dataObj.matters_ids = this.matterIds

      return dataObj
    },
    addSelectedClientsIntoSelect (id, data, selectedValues) {
      data.constructor === Array && data.forEach(element => {
        const newOption = new Option(element.text, element.id, false, false)
        $(`#${id}`).append(newOption).trigger('change')
      })
      $(`#${id}`).val(selectedValues).change()
    }
  }
}
</script>
<template>
  <select
    :id="fieldId"
    class="select2 recipient-input"
    :name="name"
    :multiple="multiple"
    style="width: 100%"
    :disabled="disabled"
  />
</template>
