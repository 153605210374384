class MattersIndex {
  constructor () {
    // initializers
    this.setStatusLabel()
  }

  setStatusLabel() {
    selectedFilterName = $(".archive-button.save-filter.active").html()
    $(".filter-by-active-dropdown").html(selectedFilterName)
    selectedFilterName = $(".filter-by-attorney-button.save-filter.active").html()
    $(".filter-by-attorney-dropdown").html(selectedFilterName)
  }
}

window.MattersIndex = MattersIndex
