<script>
import pdf from 'vue-pdf'
import DocumentField from './DocumentField'
import SignatureFieldModal from './SignatureFieldModal'
import TextFieldModal from './TextFieldModal'
import { mapGetters } from 'vuex'

export default {
  name: 'PdfPage',
  components: {
    pdf,
    DocumentField,
    SignatureFieldModal,
    TextFieldModal
  },
  props: ['src', 'pageIndex'],
  methods: {
    handlePdfPageLoaded () {
      this.$store.commit('incrementPageLoaded')
      this.$emit('pdfPageLoaded')
    }
  },
  computed: {
    ...mapGetters(['fields']),
    fieldsByCurrentPage: function() {
      let fieldsEntries = Object.entries(this.fields)
      let fieldsFiltered = fieldsEntries.filter(([fieldId, field]) => field.pageIndex == this.pageIndex)
      return Object.fromEntries(fieldsFiltered)
    }
  }
}
</script>

<template>
  <div class="pdf-page-container">
    <pdf :src="src" :page="pageIndex" @page-loaded="handlePdfPageLoaded"/>
    <div class="document-fields-container">
      <document-field v-for="(field, index) in fieldsByCurrentPage" :field="field" :key="index"/>
    </div>

    <signature-field-modal v-for="(field, index) in fieldsByCurrentPage" :field="field" :key="index" />
  	<text-field-modal v-for="(field, index) in fieldsByCurrentPage" :field="field" :key="index" />
  </div>
</template>

<style type="scss">
  .pdf-page-container {
    position: relative;
    max-width: 850px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  }

  .document-fields-container {
    position: absolute! important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }
</style>
