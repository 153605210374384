<script>
import pdf from 'vue-pdf'
import PdfPage from './PdfPage'
import PdfAuditPage from './PdfAuditPage'
import { mapGetters } from 'vuex'
const axios = require('axios').default

export default {
  name: 'ESignature',
  components: {
    PdfPage,
    PdfAuditPage
  },
  props: ['signatureDocument'],
  data() {
    return {
      pdfSrc: pdf.createLoadingTask(this.signatureDocument.document.url),
      numPages: 0
    }
  },
  mounted() {
    this.loadPdf()
    this.loadDocumentSigners()
  },
  methods: {
    loadPdf() {
      this.pdfSrc.promise.then(pdf => {
        this.numPages = pdf.numPages
      })
    },
    loadDocumentSigners() {
      this.$store.dispatch('ajaxLoadSigners', this.signatureDocument.id)
    },
    handlePdfPageLoaded () {
      if(this.pagesLoaded == this.numPages) {
        this.initPdf()
        /*
          The vue-pdf doesn't support disabling the annotation layer. Issue related #151
          While vue-pdf support it we remove annotation layer.
        */
        document.querySelectorAll(".annotationLayer").forEach(el => el.remove())
      }
    },
    async initPdf () {
      const fields = await axios.get(`/signature_documents/${this.signatureDocument.id}/document_fields`)

      for(const field of fields.data) {
        if(field.value == null && field.drawn_image.url == null) continue

        this.$store.commit('newField', {
          _id: field.id,
          type: field.type,
          pageIndex: field.page_index,
          percentWidth: parseFloat(field.percent_width),
          percentHeight: parseFloat(field.percent_height),
          percentX: parseFloat(field.percent_x),
          percentY: parseFloat(field.percent_y),
          drawnImage: field.drawn_image.url,
          value: field.value,
          hasSignerTokenBorder: field['has_signer_token_border?'],
          signature: {
            id: field.document_signer.id,
            name: field.document_signer.name,
            email: field.document_signer.email,
            code: field.document_signer.code
          }
        })
      }
    },
    async download () {
      window.location.href = `/signature_documents/${this.signatureDocument.id}/download`
    },
    close (e) {
      e.preventDefault()
      window.location.href = `/documents`
    }
  },
  computed: {
    ...mapGetters(['fields', 'pagesLoaded', 'signers']),
    signersGroupedByAuditPage () {
      const numSignersPerAuditPage = 8
      const numAuditPages = Math.ceil(this.signers.length/numSignersPerAuditPage)
      let signersByAuditPage = []

      for (let numAuditPage = 1; numAuditPage <= numAuditPages; numAuditPage++) {
        let index = (numAuditPage - 1) * numSignersPerAuditPage
        let signersGroup = this.signers.slice(index, numSignersPerAuditPage*numAuditPage)
        signersByAuditPage.push(signersGroup)
      }

      return signersByAuditPage
    }
  }
}
</script>

<template>
  <div class="row esign-sign-main-container">
    <div class="col-md-12">

      <div class="esign-sign-l-column-toolbar">
        <div class=""></div>
        <div>
          <button class="btn esign-cancel-btn" @click="close">Go Back</button>
          <a class="btn esign-continue-btn" download="dw-document-preview.pdf" :href="`/signature_documents/${this.signatureDocument.id}/download`">
            Download
          </a>
        </div>
      </div>

      <pdf-page v-for="pageIndex in numPages" :src="pdfSrc" :page-index="pageIndex" @pdf-page-loaded="handlePdfPageLoaded"/>
      <pdf-audit-page v-for="signersGroup in signersGroupedByAuditPage" :signers="signersGroup"/>
    </div>
  </div>
</template>

<style lang="scss">
  @font-face {
    font-family: "DancingScript";
    src: url('../../../assets/fonts/DancingScript-Regular.ttf');
  }
  @font-face {
    font-family: "CourierPrime";
    src: url('../../../assets/fonts/CourierPrime-Regular.ttf');
  }

  .esign-sign-main-container {
    padding: 20px 30px 30px 30px;
  }
  .esign-sign-l-column-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .esign-sign-field {
    border: 1px solid #0070BB;
    border-radius: 3px;
  }
  .esign-initials-field {
    border: 1px solid #0070BB;
    border-radius: 3px;
    color: #000;
    font-family: 'DancingScript';
    letter-spacing: 0.5px;
    font-size: 22px;
    padding: 4px 0px 0px 3px;
  }
  .esign-text-field {
    border: none;
    color: #000;
    font-family: 'CourierPrime';
    letter-spacing: 0.5px;
    font-size: 16px;
  }
  @media only screen and (max-width: 600px) {
    .esign-sign-main-container {
      padding: 10px;
    }
  }
</style>
