class ReminderForm {
  constructor ({ subscribedToSuite, reminderType = null, customAttributeId = null }) {
    // data
    this.data = {
      subscribedToSuite: subscribedToSuite === 'true',
      selectedOptionIndex: null
    }
    // initializers
    this.addReminderTypeDropdownListener()
    this.selectReminderTypeIfCustomExpiry({ customAttributeId, reminderType })
    this.addNewCustomExpiryDateOptionToReminderTypes()
    this.disablePaywallOptions()
    this.displayAssigneeAlert()
    this.addTimePairListener()
    this.addDeleteTimePairListener()
  }

  displayAssigneeAlert () {
    const reminderRecipient = $('#reminder_recipients')
    const assigneeAlert = $('#assignee_alert')
    assigneeAlert.hide()
    return reminderRecipient.change(function () {
      if (reminderRecipient[0].value === 'client_assignees') {
        return assigneeAlert.show()
      } else {
        return assigneeAlert.hide()
      }
    })
  }

  disablePaywallOptions () {
    $('#reminder_setting_reminder_type option[data-disabled="disabled"]').attr('disabled', 'disabled')
  }

  addNewCustomExpiryDateOptionToReminderTypes () {
    const optionNewExpiry = '<option value="new_custom_expiry_date">NEW CUSTOM EXPIRY DATE</option>'
    const optionNewExpiryWall = '<option value="paywall_expiry">NEW CUSTOM EXPIRY DATE (Pro & Advanced Only)</option>'

    if (this.data.subscribedToSuite) {
      $('#reminder_setting_reminder_type').append(optionNewExpiry)
    } else {
      $('#reminder_setting_reminder_type').append(optionNewExpiryWall)
    }
  }

  selectReminderTypeIfCustomExpiry ({ reminderType, customAttributeId }) {
    if (reminderType === 'custom_expiry') {
      $(`#reminder_setting_reminder_type option[data-custom-expiry-id='${customAttributeId}']`).prop('selected', true)
      return
    }
    $(`#reminder_setting_reminder_type option[value='${reminderType}']`).attr('selected', 'selected')
  }

  addReminderTypeDropdownListener () {
    const _self = this
    $('#reminder_setting_reminder_type').change(() => {
      this.onExpiryDateChange()
    }).click(() => {
      _self.setSelectedIndex()
    })
  }

  setSelectedIndex () {
    const selectedIndex = $('#reminder_setting_reminder_type').prop('selectedIndex')
    this.data.selectedOptionIndex = selectedIndex
  }

  blockSelectOption (initialSelectedIndex) {
    $('#reminder_setting_reminder_type').find(`option:eq(${initialSelectedIndex})`).prop('selected', true)
  }

  paywallPrompt () {
    const content = confirm('This requires a Docketwise Advanced subscription. Upgrade?')
    const initialSelectedIndex = this.data.selectedOptionIndex
    this.blockSelectOption(initialSelectedIndex)
    if (content === true) {
      window.location = '/account/subscription'
    }
  }

  getSelectedCustomAttributeId () {
    const selected = $('#reminder_setting_reminder_type').find('option:selected')
    return selected.data('custom-expiry-id')
  }

  onExpiryDateChange () {
    const _self = this
    const selectedReminder = $('#reminder_setting_reminder_type').val()
    const initialSelectedIndex = this.data.selectedOptionIndex
    let customAttributeId

    switch (selectedReminder) {
      case 'new_custom_expiry_date':
        this.blockSelectOption(initialSelectedIndex)
        window.location = '/custom_attributes'
        break
      case 'paywall_expiry':
        _self.paywallPrompt()
        break
      case 'custom_expiry':
        customAttributeId = _self.getSelectedCustomAttributeId()
        $('#reminder_setting_custom_attribute_id').val(customAttributeId)
        break
      default:
        $('#reminder_setting_custom_attribute_id').val(null)
        break
    }
  }

  addTimePairListener() {
    _self = this
    $(document).off('click', '#add-time-pair')
    $(document).on('click', '#add-time-pair', function(e) {
      e.preventDefault();
      notifications = $('.notification-number')
      lastNotification = notifications[notifications.length - 1]
      const newIndex = (parseInt(lastNotification?.id.match(/\d+/)[0]) || 0 ) + 1;
      const newPairHtml = _self.newPairHTML(newIndex)
      $(this).before(newPairHtml);
    })
  }

  newPairHTML (index){
    return `
      <div class="fields">
        <ul class="ul-no-bullets ul-notification-items">
          <li class="set-reminder">
            <div class="input-group-addon notification-addon-title">Notification</div>
            <input class="form-control notification-number" placeholder="00" type="text" name="reminder_setting[time_pairs][${index}][amount]" id="reminder_setting_time_pairs_${index}_amount">
            <select class="notification-time-range-selector" name="reminder_setting[time_pairs][${index}][unit]"  id="reminder_setting_time_pairs_${index}_unit">
            <option value="hours">hours</option>
            <option value="minutes">minutes</option>
            <option value="days">days</option>
            <option value="weeks">weeks</option>
            <option value="months">months</option></select>
            <a class="remove-time-pair" href="#"><span class="glyphicon glyphicon-remove remove-reminder"></span>
            </a>
          </li>
        </ul>
      </div> 
    `;
  }

  addDeleteTimePairListener (){
    $(document).off('click', '.remove-time-pair')
    $(document).on('click', '.remove-time-pair', function(e) {
      e.preventDefault();
      $(this).closest('.fields').remove();
    });
  }
}

window.ReminderForm = ReminderForm
