<!-- EquivalencyDropdown.vue -->
<script>
export default {
  props: ['filter', 'filterGroup'],
  computed: {
    label () {
      const { filterGroup, filter } = this
      return this.$store.getters.filterLabel(filterGroup, filter)
    },
    equivalencyOptions () {
      const { filter } = this
      return this.$store.getters.filterEquivalencyOptions(filter) || []
    },
    equivalency () {
      const { filterGroup, filter } = this
      return this.$store.getters.filterEquivalency(filterGroup, filter)
    }
  },
  methods: {
    updateEquivalency: function (value) {
      const { filterGroup, filter } = this
      this.$store.dispatch('updateEquivalency', { filterGroup, filter, value })
      this.resetInputTextFilterValue()
    },
    resetInputTextFilterValue: function () {
      const { filterGroup, filter } = this
      this.$store.commit('resetInputTextFilterValue', { filter, filterGroup })
    }
  }
}
</script>
<template>
  <multi-select
    v-model="equivalency"
    class="table-query-builder__matcher-selector"
    :allow-empty="false"
    :show-labels="false"
    :placeholder="'Select a matcher'"
    label="name"
    track-by="name"
    :options="equivalencyOptions"
    @select="updateEquivalency"
  />
</template>
