<!-- LabelDropdown.vue -->
<script>
import { get } from 'lodash'
import { mapState } from 'vuex'

export default {
  props: ['filter', 'filterableColumns', 'filterGroup', 'resetFilter'],
  computed: {
    ...mapState({
      filterableColumns: state => state.filterableColumns,
      conditionOperators: state => state.conditionOperators
    }),
    enumOptions () {
      const { filter } = this
      const filterLabelValue = get(filter, 'label.value', null)
      const enumOptions = this.filterableColumns.find(lo => lo.value === filterLabelValue)
      return (enumOptions && enumOptions.options) || []
    },
    label () {
      const { filterGroup, filter } = this
      return this.$store.getters.filterLabel(filterGroup, filter)
    }
  },
  methods: {
    updateLabel: function (value) {
      const { filterGroup, filter } = this
      this.$store.commit('updateFilterLabel', { filter, filterGroup, value })
      this.$store.commit('setEquivalencyOptions', { filter, filterGroup })
      this.resetFilter()
    }
  }
}
</script>
<template>
  <multi-select
    v-model="label"
    :max-height="250"
    class="table-query-builder__column-selector"
    :allow-empty="false"
    :show-labels="false"
    :placeholder="'Select a column'"
    :options="filterableColumns"
    label="name"
    track-by="name"
    @select="updateLabel"
  />
</template>
