/* eslint-disable camelcase */
import utils from '../text_messaging/tm_utilities'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone.js'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.guess()

export default class TextMessagingDashboard {
  constructor ({
    textMessagingEnabled,
    textMessagingTimeZone,
    pusherKey,
    pusherTimeoutFetch,
    firmId,
    bus
  }) {
    const dasboardId = 'text-messaging-dashboard'

    utils.createDashboardDomElement(dasboardId)

    new Vue({
      el: `#${dasboardId}`,
      filters: {
        format_relative_time: function (date) {
          return dayjs.utc(date).tz(textMessagingTimeZone).fromNow()
        }
      },
      data () {
        return {
          searchText: '',
          conversations: [],
          conversationsDetail: [],
          activeConversationId: null,
          conversationsCurrentPage: 1,
          lastMessageId: null,
          isDashboardOpened: false,
          filterConversationsByUser: false,
          maxmessagelength: 480,
          messagesPerPage: 10,
          messagelength: 0,
          devbuttons: false,
          nexConversationsPage: false
        }
      },
      computed: {
        conversationsList () {
          return this.conversations || []
        },
        validConversationSelected () {
          const activeConversationId = this.activeConversationId
          const conversations = this.conversations
          const activeConversation =
            conversations &&
            conversations.find((c) => c.id === activeConversationId)
          return activeConversation && activeConversationId !== null
        },
        activeMessages () {
          const activeConversation = this.conversationsDetail.find(
            (c) => c.id === this.activeConversationId
          )
          return activeConversation
            ? activeConversation &&
                activeConversation.messages &&
                activeConversation.messages.slice().reverse()
            : []
        },
        textMessagingEnabled () {
          return textMessagingEnabled
        },
        recipientName () {
          const client =
            this.conversations &&
            this.conversations.find(
              (chat) => chat.id === this.activeConversationId
            )
          return _.get(client, 'recipient_name', '')
        },
        clientName () {
          const client =
            this.conversations &&
            this.conversations.find(
              (chat) => chat.id === this.activeConversationId
            )
          return _.get(client, 'client.name', '')
        },
        clientMatter () {
          const client =
            this.conversations &&
            this.conversations.find(
              (chat) => chat.id === this.activeConversationId
            )
          return _.get(client, 'matter.title', '')
        },
        clientPhoneNumber () {
          const client =
            this.conversations &&
            this.conversations.find(
              (chat) => chat.id === this.activeConversationId
            )
          return _.get(client, 'recipient_mobile_phone', '')
        },
        conversationsByUserDropdownText () {
          const fcbu = this.filterConversationsByUser
          return fcbu === true ? 'My Conversations' : 'Firm\'s Conversations'
        },
        conversationsByUserSelectText () {
          const fcbu = this.filterConversationsByUser
          return fcbu === false ? 'My Conversations' : 'Firm\'s Conversations'
        }
      },
      mounted () {
        // listeners for custom actions that connect with other components
        bus.$on('open-dashboard', ({ coversationId }) => {
          this.openTextMessagesDashboard({ coversationId })
        })

        // handler for conversations at /messages -> messages
        this.conversationRowClickHandler()

        if (textMessagingEnabled === 'true') {
          // logic related to chat inverted scroll
          this.onChatContainerScroll()
          // fetch unseen conversation to populate bubble on nav icon
          this.fetchUnseenConversations()
          // pusher listeners for incoming messages and when a message is delivered
          const pusher = new Pusher(pusherKey, { cluster: window.pusherCluster })
          this.initializePusher({ pusher })
          this.noPusherConnectionFallback({ pusher })
        }
      },
      updated () {},
      methods: {
        formatDateTimeTimeStamp (date) {
          const format = 'ddd DD-MMM-YYYY, hh:mm A'
          const calculatedDate = dayjs.utc(date).tz(textMessagingTimeZone).format(format)
          return calculatedDate
        },
        returnNull () {
          return null
        },
        debounceSearchInput: _.debounce(function (e) {
          this.searchText = e.target.value
          this.fetchConversations(null, true)
        }, 750),
        onChatContainerScroll () {
          const that = this
          $('.chat-container').scroll(function () {
            const pos = $(this).scrollTop()
            if (pos < 1) {
              that.fetchConversation({
                id: that.activeConversationId,
                goToTop: true
              })
            }
          })
        },
        conversationRowClickHandler: function () {
          const that = this
          $(document).on('click', '.message-table-tr td', function () {
            const type = $(this).parent().data('type')

            if (!$(this).hasClass('link-container') && type !== 'message') {
              const conversation_id = $(this).parent().data('message-id')
              const element = document.getElementById(dasboardId)

              that.setActiveConversationId(conversation_id)
              element.classList.toggle('disabled')

              setTimeout(function () {
                $('.inner-text-messaging-dashboard').removeClass(
                  'hide-at-left'
                )
                that.fetchConversations(() => {
                  const arr = (that && that.conversations) || []

                  if (
                    utils.validateArrayLength(arr) > 0 &&
                    that.activeConversationId === null
                  ) {
                    that.selectConversation(that.conversations[0].id)
                  }

                  if (
                    utils.validateArrayLength(arr) > 0 &&
                    that.activeConversationId !== null
                  ) {
                    that.selectConversation(that.activeConversationId)
                    that.scrollToConversation(that.activeConversationId)
                  }
                })
              }, 200)

              if (!element.classList.contains('disabled')) {
                document.body.style.height = '100%'
                document.getElementsByTagName('html')[0].style.height = '100%'
              }
            }
          })
        },
        initializePusher: function ({ pusher }) {
          const channel_name = `sms-chat-channel-firm-${firmId}`
          const channel = pusher.subscribe(channel_name)

          channel.bind('receive-sms', (data) => {
            const that = this
            const { unseen_conversations } = data
            const activeConversationId = this.activeConversationId
            utils.smsCounterBubble({ number: unseen_conversations })

            if (typeof activeConversationId === 'number') {
              this.fetchConversations(() => {
                this.fetchConversation({
                  id: activeConversationId,
                  reset: true,
                  goToBottom: that.isDashboardOpened
                })
              })
            } else {
              this.fetchConversations()
            }
          })

          channel.bind('track-sms', (data) => {
            const that = this
            const activeConversationId = this.activeConversationId
            if (typeof activeConversationId === 'number') {
              this.fetchConversations(() => {
                this.fetchConversation({
                  id: activeConversationId,
                  reset: true,
                  goToBottom: that.isDashboardOpened
                })
              })
            } else {
              this.fetchConversations()
            }
          })
        },
        setDashboardStatus ({ open = true }) {
          this.isDashboardOpened = !!open
        },
        setActiveConversationId: function (conversationId) {
          this.activeConversationId = conversationId
          store.set('textMessagingActiveConversationId', conversationId)
        },
        displayError (errObj) {
          let error = errObj && errObj.error
          if (!(errObj && errObj.error)) {
            error = 'Something went wrong'
          }
          toastr.error(error)
        },
        toggleConversationsFilter (event) {
          event.preventDefault()
          this.filterConversationsByUser = !this.filterConversationsByUser
          this.fetchConversations()
        },
        lastConversationMessage (conversation) {
          return _.get(conversation, 'last_message', '')
        },
        lastConversationDate (conversation) {
          const date = _.get(conversation, 'last_message[0].date', null)
          return date ? this.$options.filters.format_relative_time(date) : ''
        },
        findConversation (id) {
          return this.conversations.find((c) => c.id === id)
        },
        showPaywallModal () {
          $('#paywall-sms-chat-modal').modal('show')
        },
        resetNewConversationModal () {
          // wrappers
          const textMessagingNewConversationPhoneWrapper = $(
            '#text-messaging-new-conversation-phone-input-wrapper'
          )
          const textMessagingNewConversationCheckboxAddClientAndOrMatterWrapper =
            $(
              '#text-messaging-new-conversation-add-client-or-matter-checkbox-wrapper'
            )
          const textMessagingNewConversationClientAndMatterWrapper = $(
            '#text-messaging-new-conversation-add-client-or-matter-inputs-wrapper'
          )
          // inputs
          const textMessagingClientOrMatterCheckbox = $(
            '#text-messaging-new-conversation-link-to-client-or-matter-checkbox'
          )
          const textMessagingRecipientInput = $(
            '#text-messaging-new-conversation-recipient-input'
          )
          const textMessagingPhoneInput = $(
            '#text-messaging-new-conversation-phone-input'
          )
          // reset
          utils.resetSelect2(textMessagingRecipientInput)
          textMessagingPhoneInput.val(null)
          textMessagingClientOrMatterCheckbox.prop('checked', false)
          $('.action-conversation-btn').removeAttr('disabled')
          // hide
          textMessagingNewConversationClientAndMatterWrapper.hide()
          textMessagingNewConversationPhoneWrapper.hide()
          textMessagingNewConversationCheckboxAddClientAndOrMatterWrapper.hide()
        },
        openNewConversationModal ({ resetFields = false }) {
          textMessagingEnabled === 'false' ? this.showPaywallModal() : this.returnNull()

          if (textMessagingEnabled === 'true' && resetFields === true) {
            $('#text-messaging-new-conversation-modal').modal('show')
            this.resetNewConversationModal()
          }
        },
        openEditConversationModal ({ resetFields = false }) {
          const activeConversation = this.findConversation(
            this.activeConversationId
          )
          const { matter, client } = activeConversation
          const matterTitle = matter && matter.title ? matter.title : ''
          const matterId = matter && matter.id ? matter.id : ''

          this.setActiveConversationId(this.activeConversationId)

          $('.action-conversation-btn').removeAttr('disabled')
          $('#text-messaging-edit-conversation-modal').modal('show')

          if (resetFields === true) {
            const editClientInput = $('.client-input')
            const editMatterInput = $('#edit-conversation-matter-input')

            utils.resetSelect2(editClientInput)
            if (client && client.id && client && client.name) {
              const selectedClientOption = new Option(
                client.name,
                client.id,
                false,
                false
              )
              editClientInput.append(selectedClientOption)
              editClientInput.select2('data', {
                id: client.id,
                label: client.name
              })
              editClientInput.trigger('change')
            }

            utils.resetSelect2(editMatterInput)
            if (matter && matter.id && matter && matter.title) {
              const selectedMatterOption = new Option(
                matterTitle,
                matterId,
                false,
                false
              )
              editMatterInput.append(selectedMatterOption)
              editMatterInput.select2('data', {
                id: matterId,
                label: matterTitle
              })
              editMatterInput.trigger('change')
            }
          }
        },
        typeOfMessage: function ({ is_user, status }) {
          if (is_user !== true) {
            return status
          }
          switch (status) {
            case 'delivered':
              return 'sender'
            case 'undelivered':
              return 'sender-undelivered'
            case 'sending':
              return 'sender-sending'
            case 'created':
              return 'sender-created'
            case 'error':
              return 'sender-error'
            default:
              return status
          }
        },
        fetchUnseenConversations: function () {
          $.ajax({
            type: 'GET',
            url: '/conversations/unseen.json',
            success: function (response) {
              utils.smsCounterBubble({ number: response.unseen_conversations })
            }
          })
        },
        scrollToConversation: function (id) {
          // scroll active conversation at left column into view
          const conversationEl = document.getElementById(
            `text-messaging-conversation-${id}`
          )
          setTimeout(() => {
            conversationEl && conversationEl.scrollIntoView()
          }, 0)
        },
        fetchConversation: function ({
          id,
          reset = false,
          goToBottom = false,
          goToTop = false
        }) {
          if (id === undefined || id === null) {
            return null
          }
          const url = new URL(`/conversations/${id}.json`, document.baseURI)
          const lastMessageBeforeUpdate = this.lastMessageId
          url.searchParams.set('per_page', this.messagesPerPage)

          if (
            this.lastMessageId !== null &&
            this.lastMessageId !== undefined &&
            reset === false
          ) {
            url.searchParams.set('last_message_id', this.lastMessageId)
          }

          $.ajax({
            type: 'GET',
            url,
            success: (response) => {
              const _response = { ...response, messages: response?.messages || [] }
              const newMessages = _response && _response.messages
              const conversation =
                this.conversationsDetail.find((c) => c.id === _response.id) ===
                undefined
                  ? this.conversationsDetail.push(_response) &&
                    this.conversationsDetail.find((c) => c.id === _response.id)
                  : this.conversationsDetail.find((c) => c.id === _response.id)

              if (newMessages && newMessages.length === 0) {
                return null
              }
              const lastMessage = newMessages[newMessages.length - 1]
              this.lastMessageId = lastMessage && lastMessage.id

              conversation.messages =
                reset === false
                  ? conversation && conversation.messages
                    ? conversation.messages.concat(newMessages)
                    : newMessages
                  : newMessages

              // scroll chat to bottom
              if (goToBottom === true) {
                setTimeout(() => {
                  this.scrollChatToBottom()
                }, 5)
              }

              // scroll chat to top offset
              if (goToTop === true && lastMessageBeforeUpdate) {
                const targetMessage = document.getElementById(
                  lastMessageBeforeUpdate
                )
                setTimeout(() => {
                  targetMessage && targetMessage.scrollIntoView()
                }, 0)
              }

              this.fetchUnseenConversations()
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
              const generalError = 'Something went wrong, we could not load the conversation.'
              toastr.error(generalError)
              console.warn(textStatus)
            }
          })
        },
        fetchConversations: function (
          successCallback,
          resetActiveConversation = false
        ) {
          if (textMessagingEnabled === 'true') {
            if (resetActiveConversation === true) {
              this.activeConversationId = null
            }
            const that = this
            const url = new URL('/conversations.json', document.baseURI)
            url.searchParams.set('per_page', 30)
            this.searchText !== ''
              ? url.searchParams.set('conversation_search', this.searchText)
              : that.returnNull()
            this.filterConversationsByUser === true
              ? url.searchParams.set('filter_by_user', true)
              : that.returnNull()
            url.searchParams.set('page', this.conversationsCurrentPage)

            $.ajax({
              type: 'GET',
              url,
              success: function (response, _, request) {
                const paginationHeader = JSON.parse(request.getResponseHeader('X-Pagination'))
                that.nexConversationsPage = paginationHeader.next_page
                const { conversations, unseen_conversations } = response
                utils.smsCounterBubble({ number: unseen_conversations })
                that.conversations = conversations
                if (successCallback && typeof successCallback === 'function') {
                  successCallback()
                }
              },
              error: function (XMLHttpRequest, textStatus, errorThrown) {
                let generalError = 'Something went wrong, we could not load the conversations.'
                if (XMLHttpRequest.responseJSON && XMLHttpRequest.responseJSON.error) {
                  generalError = XMLHttpRequest.responseJSON.error
                }
                toastr.error(generalError)
                console.warn(errorThrown)
              }
            })
          }
        },
        selectConversation: function (id) {
          this.setActiveConversationId(id)
          this.fetchConversation({
            id,
            reset: true,
            goToBottom: true
          })
        },
        clickConversationHandler: function (conversation) {
          const { id } = conversation
          this.selectConversation(id)
        },
        messageInputHandler: function (el) {
          // track message length
          this.messagelength = el.target.value.length
        },
        scrollChatToBottom: function () {
          $('.chat-container').animate(
            { scrollTop: $('.chat-list').height() },
            0
          )
        },
        showModalUpdateCreditCardInfo: function (el) {
          $('body').append('<a style="display:none;" id="update_credit_card_info_link" data-remote="true" href="/billings/new?billing_type=update_info_text_messaging_card"></a>')

          $('#update_credit_card_info_link')
            .click()
            .remove()
        },
        createMessage: function (el) {
          let message = el.target.value
          // clean break line and extra spaces from message
          message = message.replace('\n', '').replace(/\s+/g, ' ').trim()
          const tempId = utils.uuidv4()

          if (message.length > this.maxmessagelength) {
            return null
          }

          if (message.length > 0) {
            const conversationId = this.activeConversationId
            const url = `/conversations/${conversationId}/send_message`
            const data = { body: message }
            const that = this

            el.target.disabled = true

            const conversationIndex = that.conversationsDetail.findIndex(
              (c) => c.id === that.activeConversationId
            )

            that.conversationsDetail[conversationIndex].messages.unshift({
              id: tempId,
              status: 'created',
              is_user: true,
              date: Date.now(),
              body: message
            })

            that.scrollChatToBottom()

            const objMessageIndex = that.conversationsDetail[
              conversationIndex
            ].messages.findIndex((m) => m.id === tempId)

            $.ajax({
              type: 'POST',
              data,
              url,
              success: function () {
                el.target.disabled = false
                el.target.value = null
                that.conversationsDetail[conversationIndex].messages[
                  objMessageIndex
                ].status = 'sending'
                that.scrollChatToBottom()
                that.scrollToConversation(conversationId)
              },
              error: function (XMLHttpRequest) {
                el.target.disabled = false
                el.target.value = null

                const errObj = XMLHttpRequest.responseJSON
                that.conversationsDetail[conversationIndex].messages[
                  objMessageIndex
                ].status = 'error'

                that.scrollChatToBottom()
                if (errObj && errObj.payment_error === true) {
                  toastr.error(errObj.error)
                  that.showModalUpdateCreditCardInfo()
                } else if(errObj && errObj.error) {
                  toastr.error(errObj.error)
                } else {
                  const generalError = 'Something went wrong, we could not send the message.'
                  toastr.error(generalError)
                }

                console.warn(errObj)
              }
            })
            el.preventDefault()
          } if (message.length === 0) {
            el.target.value = null
          }
        },
        noPusherConnectionFallback: function ({ pusher }) {
          const that = this
          setInterval(() => {
            if (
              textMessagingEnabled === 'true' &&
              pusher.connection.state !== 'connected'
            ) {
              if (that.isDashboardOpened === true) {
                if (typeof that.activeConversationId === 'number') {
                  that.fetchConversations(function () {
                    that.fetchConversation({
                      id: that.activeConversationId,
                      reset: true,
                      goToBottom: true
                    })
                  })
                } else {
                  that.fetchConversations()
                }
              } else {
                that.fetchUnseenConversations()
              }
            }
          }, pusherTimeoutFetch)
        },
        toggleInnerStylesForPayWall: function ({ textMessagingEnabled }) {
          textMessagingEnabled === 'false'
            ? $('.inner-text-messaging-dashboard').addClass(
              'small-width-dashboard'
            )
            : $('.inner-text-messaging-dashboard').removeClass(
              'small-width-dashboard'
            )
        },
        uiOpenDashboardLogic: function () {
          $(`#${dasboardId}`).removeClass('disabled')
          $('.inner-text-messaging-dashboard').removeClass('hide-at-left')
        },
        openTextMessagesDashboard: function ({ coversationId = null }) {
          this.toggleOpenDashboardButton('none')
          if (textMessagingEnabled === 'true') {
            this.fetchConversations(() => {
              this.uiOpenDashboardLogic()
              this.uiDashboardFullScreenHotfix({ add: true })
              setTimeout(() => {
                this.setDashboardStatus({ open: true })
              }, 200)
            })
          } else {
            this.toggleInnerStylesForPayWall({ textMessagingEnabled })
            this.uiDashboardFullScreenHotfix({ add: true })
            this.uiOpenDashboardLogic()
          }
        },
        toggleOpenDashboardButton: function (pointerValue = '') {
          document.getElementById('btn-open-user-text-messaging').style.pointerEvents = pointerValue
        },
        uiDashboardFullScreenHotfix: function ({
          add = false,
          remove = false
        }) {
          const textMessagingDashboard = document.getElementById(dasboardId)

          if (add === true) {
            textMessagingDashboard.classList.remove('disabled')
            document.body.style.height = '100%'
            document.getElementsByTagName('html')[0].style.height = '100%'
          }

          if (remove === true) {
            textMessagingDashboard.classList.add('disabled')
            document.body.style.height = null
            document.getElementsByTagName('html')[0].style.height = null
          }
        },
        closeTextMessagesDashboard: function () {
          $('.inner-text-messaging-dashboard').addClass('hide-at-left')

          // set a delay for the close animation
          setTimeout(() => {
            this.uiDashboardFullScreenHotfix({ remove: true })
            this.setDashboardStatus({ open: false })
            this.activeConversationId = null
            this.toggleOpenDashboardButton()
          }, 300)
        },
        updatePage: function(page) {
          this.conversationsCurrentPage = page
          this.fetchConversations()
        }
      },
      template: '#text-messaging-dashboard-vue-template'
    })
  }
}
