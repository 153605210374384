class LegacyCustomTabs {
  constructor (activeTab, tabsObj, container, onTabSelected = null) {
    this.activeTab = activeTab
    this.onTabSelected = onTabSelected
    this.computedTabs = this.removeDuplicates(tabsObj)
    this.containerElement = $(container)
    if (this.containerElement.length > 0) {
      this.renderTabs()
    } else {
      console.error('Container not found')
    }
  }

  removeDuplicates (arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index)
  }

  activeTabClass (tab) {
    return tab.name === this.activeTab || tab.key === this.activeTab
  }

  handleClick (tab, event) {
    if (!tab.url) {
      event.preventDefault()
      this.activeTab = tab.key || tab.name
      this.onTabSelected && this.onTabSelected(tab)
    }
  }

  disableLink (tabName) {
    if (tabName === 'Invoices' && this.blockInvoicesAccess === 'true') {
      return 'true'
    } else if (tabName === 'Bank Accounts' && this.bankAccountsAccess !== 'true') {
      return 'true'
    }
    return null
  }

  renderTabs() {
    const ulElement = $('<ul>')

    this.computedTabs.forEach(tab => {
      const liElement = $('<li>')
      this.activeTabClass(tab) && liElement.addClass('active')
      this.disableLink(tab.name) && liElement.addClass('disabled')

      const aElement = $('<a>').attr({
        'href': tab.url || '#', // Set href to '#' if tab.url is falsy
        'disabled': this.disableLink(tab.name)
      }).on('click', (event) => {
        this.containerElement.find('li').removeClass('active')
        this.handleClick(tab, event)
        $(event.target.parentNode).addClass('active')
      }).text(tab.name)

      liElement.append(aElement)
      ulElement.append(liElement)
    })

    this.containerElement.append(ulElement)
  }
}

export default LegacyCustomTabs
