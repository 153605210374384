<script>
import './hello-vue.scss'
export default {
  props: ['greeting']
}
</script>
<template>
  <p>
    <span>
      Hello Vue {{ greeting }}
      <slot />
    </span>
  </p>
</template>
