import utils from '../text_messaging/tm_utilities'

Vue.component('tm-edit-conversation-modal', {
  props: ['fetchConversations', 'fetchConversation', 'displayError'],
  mounted () {
    // select2 instances
    const textMessagingEditConversationSelect2Client = new ApplicationParty()
    const textMessagingEditConversationSelect2Matter = new Matter()
    // select2 initializer
    textMessagingEditConversationSelect2Client.initializeSelect2(
      '/contacts/new',
      '#edit-conversation-client-input',
      false,
      $('#text-messaging-edit-conversation-modal')
    )
    textMessagingEditConversationSelect2Client.displayClientModal(
      '/contacts/new',
      false,
      '#edit-conversation-client-input'
    )
    textMessagingEditConversationSelect2Matter.initializeSelect2(
      '/matters/new',
      '#edit-conversation-matter-input',
      '#edit-conversation-client-input',
      () => $('#edit-conversation-client-input').val(),
      $('#text-messaging-edit-conversation-modal')
    )
    textMessagingEditConversationSelect2Matter.displayMatterModal(
      '/matters/new',
      '#edit-conversation-matter-input',
      '#edit-conversation-client-input'
    )

    $('#edit-conversation-client-input').on('change', function (e) {
      utils.resetSelect2($('#edit-conversation-matter-input'))
    })

    $('#edit-conversation-client-input').on('select2:open', () => {
      setTimeout(() => {
        $('.select2-search__field').focus()
      }, 128)
    })

    $('#edit-conversation-matter-input').on('select2:open', () => {
      setTimeout(() => {
        $('.select2-search__field').focus()
      }, 128)
    })
  },
  methods: {
    submitHandler: function (event) {
      event.preventDefault()
      const conversationId = store.fetch('textMessagingActiveConversationId')
      this.editConversation({ id: conversationId })
    },
    uiOnSucess: function () {
      $('#text-messaging-edit-conversation-modal').modal('hide')
      toastr.success('Conversation has been succesfuly updated.')
    },
    uiOnOpen: function () {
      $('.action-conversation-btn').removeAttr('disabled')
    },
    uiOnClose: function () {
      $('.action-conversation-btn').removeAttr('disabled')
    },
    editConversation: function ({ id }) {
      const url = `/conversations/${id}`
      const client = $('#edit-conversation-client-input').select2('data')
      const matter = $('#edit-conversation-matter-input').select2('data')

      const conversationObject = {
        client_id: _.get(client, '[0].id', null),
        matter_id: _.get(matter, '[0].id', '')
      }

      this.uiOnOpen()

      $.ajax({
        type: 'PUT',
        url,
        data: { conversation: conversationObject },
        success: () => {
          this.uiOnSucess()
          this.fetchConversations()
        },
        error: (XMLHttpRequest) => {
          this.uiOnClose()
          const errObj = XMLHttpRequest.responseJSON
          this.displayError(errObj)
        }
      })
    }
  },
  template: '#text-messaging-edit-conversation-modal-container'
})
