class EditAssembly {
  constructor ({ smartFormId }) {
    this.data = {
      smartFormId: smartFormId
    }
    // initializers
    if (smartFormId !== undefined) {
      this.inviteModalButtonListener()
    }
    this.addListenerToCopyLink()
    this.addListenerSelectContact()
  }

  constructInviteModalRows(event, contactId) {
    _self = this;
    event.preventDefault()
    // fetch invites data for the specific smart form and contact

    $.ajax({
      url : `/smart_forms/:id/smart_forms/${_self.data.smartFormId}/contact/${contactId}`,
      type: 'GET',
      contentType: "application/json",
      dataType: "json",
      success : function (response) {
        // construct invitations rows

        const invitations = response.invitations.sort((a, b) => b.id - a.id)
        const trHTML = _self.constructInvitationRows(invitations)

        // append trHTML and show the modal
        $('#smart-forms-invite-modal table tbody').empty().append(trHTML)
        $('#smart-forms-invite-modal').modal('show')
      }
    })
  }

  constructInvitationRows (invitations) {
    _self = this
    let trHTML = ''

    $.each(invitations, function (_i, invite) {
      // if is a link, show copy link actions if not, show re-send action
      const actionButton = _self.actionButtonByType(invite)

      // construct the table row inside trHTML variable
      trHTML += '<tr><td>' +
      invite.name + '</td><td>' +
      invite.phone_or_email +
      '</td><td>' +
      invite.status +
      '</td><td>' +
      invite.language +
      '</td><td>' +
      invite.last_sent_date +
      '</td><td class="dw-capitalized-text">' +
      invite.Last_delivered_through +
      '</td><td>' +
      actionButton +
      '</td></tr>'
    })

    return trHTML
  }

  actionButtonByType (invite) {
    if (invite.status === 'Revoked') {
      return '<span>Revoked</span>'
    } else if (invite.is_link) {
      return `<a data-link-url="${invite.link_url}" class="no-margin copy-invite-link">Copy Link</a>`
    } else {
      return `<a data-form-id="${invite.form_id}" data-resend-url="/en/form_invitations/${invite.id}/resend?form_id=${invite.form_id}" class="no-margin resend-invite">Resend</a> / <a class="revoke-form-invitation" data-form-id="${invite.id}">Revoke</a>`
    }
  }

  inviteModalButtonListener () {
    const _self = this;
    $('body').on('click', '.smart-forms-invite-modal-open-btn', function(event) {
      const contactId = $(this).data("contact-id")
      _self.constructInviteModalRows(event, contactId)
    })
  }

  addListenerToCopyLink(callback) {
    $('body').on('click', '#smart-forms-invite-modal .copy-invite-link', function(event) {
      const linkUrl = $(this).data("link-url");
      copyInviteLink(linkUrl);
    });
  }

  addListenerSelectContact () {
    $('body').on('click', '#invite_modal .select-contact', function(event) {
      let contact = undefined
      const contactId = $(this).data("contact-id")

      // fetch contact info
      $.ajax({
        url : `/form_invitations/contact_json/${contactId}`,
        type: 'GET',
        success : function (contact) {
          if (contact) {
            // populate select2 with contact
            const option = new Option(contact.full_name_with_email, contact.id)
            option.selected = true
            $('#invitee').append(option).trigger('change')
            $('#invitee').trigger({type: 'select2:select', params: { data: {number: contact.mobile_phone_number} }})
          }
        }
      })
    })
  }
}

window.EditAssembly = EditAssembly
