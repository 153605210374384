<!-- RemoveButton.vue -->
<script>
export default {
  props: ['filter', 'filterGroup', 'removeFilter']
}
</script>
<template>
  <button
    class="table-query-builder__filter__remove-button"
    @click="removeFilter({filterGroup, filter})"
  >
    <img
      class="table-query-builder__x-icon"
      src="~images/custom_reports/x-icon.svg"
    >
  </button>
</template>
