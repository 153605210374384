<script>
import VueDraggableResizable from 'vue-draggable-resizable'
// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  name: 'DocumentField',
  components: {
    VueDraggableResizable
  },
  props: ['field'],
  data() {
    return {
      percentX: this.field.percentX,
      percentY: this.field.percentY,
      percentWidth: this.field.percentWidth,
      percentHeight: this.field.percentHeight
    }
  },
  computed: {
    posX () {
      const pdfContainer = document.getElementsByTagName("canvas")[0].getBoundingClientRect()
      return Math.floor((pdfContainer.width * this.percentX) / 100)
    },
    posY () {
      const pdfContainer = document.getElementsByTagName("canvas")[0].getBoundingClientRect()
      return Math.floor((pdfContainer.height * this.percentY) / 100)
    },
    width () {
      const pdfContainer = document.getElementsByTagName("canvas")[0].getBoundingClientRect()
      return Math.floor((pdfContainer.width * this.percentWidth) / 100)
    },
    height () {
      const pdfContainer = document.getElementsByTagName("canvas")[0].getBoundingClientRect()
      const height = Math.floor((pdfContainer.height * this.percentHeight) / 100)

      if(['SignatureField', 'InitialsField'].includes(this.field.type)) {
        const signerTokenHeight = 10
        return height - signerTokenHeight
      } else {
        return height
      }
    },
    tokenPositionStyle () {
      const tokenPosX = this.posX
      const tokenPosY = this.posY + this.height
      return `translate(${tokenPosX}px, ${tokenPosY}px)`
    },
    fieldClassName () {
      const classNames = {
        'SignatureField': 'esign-sign-field vdr',
        'InitialsField': 'esign-initials-field vdr',
        'TextField': 'esign-text-field vdr',
        'DateField': 'esign-text-field vdr',
      }
      return classNames[this.field.type]
    }
  }
}
</script>

<template>
  <div>
    <vue-draggable-resizable :w="width" :h="height" minWidth="200" minHeight="40" :x="posX" :y="posY"  :parent="true" :draggable="false" :resizable="false" :class-name="fieldClassName">
      <img v-if="field.drawnImage" :src="field.drawnImage" :width="width" :height="height"/>
      <span v-if="field.value">{{ field.value }}</span>
    </vue-draggable-resizable>
    <div :style="{ transform: tokenPositionStyle }" class="signature-token" v-if="field.hasSignerTokenBorder">
      ID: {{ field.signature.code }}
    </div>
  </div>
</template>

<style type="text/css">
  .signature-token {
    position: absolute;
    font-size: 10px;
    color: #0070BB;
    font-family: 'Helvetica-Bold';
    font-weight: bold;
  }
</style>
