import axios from 'axios'

const urlBase = 'https://to-be-defined.later'

export default {
  async improveWriting (text) {
    try {
      const path = '/improve-writing'
      const response = await axios.get(`${urlBase}${path}?text=${text}`)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async changeTone (text, tone) {
    try {
      const path = '/change-tone'
      const response = await axios.get(`${urlBase}${path}?text=${text}&tone=${tone}`)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async translateTo (text, language) {
    try {
      const path = '/translate'
      const response = await axios.get(`${urlBase}${path}?text=${text}&language=${language}`)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async makeShorter (text) {
    try {
      const path = '/make-shorter'
      const response = await axios.get(`${urlBase}${path}?text=${text}`)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async makeLonger (text) {
    try {
      const path = '/make-longer'
      const response = await axios.get(`${urlBase}${path}?text=${text}`)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async summarize (text) {
    try {
      const path = '/summarize'
      const response = await axios.get(`${urlBase}${path}?text=${text}`)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async suggestActionItems (text) {
    try {
      const path = '/suggest-action-items'
      const response = await axios.get(`${urlBase}${path}?text=${text}`)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async explain (text) {
    try {
      const path = '/explain'
      const response = await axios.get(`${urlBase}${path}?text=${text}`)
      return response
    } catch (error) {
      console.error(error)
    }
  }
}
