<script>
export default {
  name: 'PdfAuditPage',
  props: ['signers']
}
</script>

<template>
  <div class="pdf-page-logs-container">
    <h1 class="signer-logs-title">eSignature Details</h1>
    <hr>
    <div v-for="signer in signers" class="signer-log">
      <div v-if="signer.submittedAt != null">
        <div class="row signer-id">
          <div class="col-sm-3">Signer ID:</div>
          <div class="col-sm-9">{{ signer.code }}</div>
        </div>

        <div class="row">
          <div class="col-sm-3">Signed by:</div>
          <div class="col-sm-9">{{ signer.name }}</div>
        </div>

        <div class="row" v-if="signer.notifyViaEmail">
          <div class="col-sm-3">Sent to email:</div>
          <div class="col-sm-9">{{ signer.email }}</div>
        </div>

        <div class="row" v-if="signer.notifyViaSms">
          <div class="col-sm-3">Sent to phone number:</div>
          <div class="col-sm-9">{{ signer.phone_number }}</div>
        </div>

        <div class="row">
          <div class="col-sm-3">IP Address:</div>
          <div class="col-sm-9">{{ signer.submittedIpAddress }}</div>
        </div>

        <div class="row">
          <div class="col-sm-3">Signed at:</div>
          <div class="col-sm-9">{{ signer.submittedAt }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @font-face {
    font-family: "Roboto";
    src: url('../../../assets/fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "RobotoBold";
    src: url('../../../assets/fonts/Roboto-Bold.ttf');
    font-weight: normal;
    font-style: normal;
  }

  .pdf-page-logs-container {
    font-family: 'Roboto';
    font-size: 14px;
    max-width: 850px;
    max-height: 1100px;
    height: 1100px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 72px;

    .signer-logs-title {
      font-family: 'Roboto';
      font-size: 26px;
      letter-spacing: 0.5px;
    }

    hr {
      border: 1px solid black;
    }

    .signer-log {
      margin-bottom: 15px;
    }

    .signer-id {
      font-family: 'RobotoBold';
    }
  }
</style>
