<script>
import './select2-user.scss'
export default {
  name: 'select2-user',
  props: {
    name: {
      type: String,
      required: true
    },
    fieldId: {
      type: String,
      required: true
    },
    multiple: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Type or select user'
    },
    queryParameters: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.configureSelect2(this.fieldId, this.placeholder)
    // the data is filled into select in app/assets/javascripts/note_builder.coffee:59
  },
  methods: {
    configureSelect2 (id, placeholder) {
      $(`#${id}`).select2({
        ajax: {
          url: '/users',
          dataType: 'json',
          delay: 250,
          data: this.processData,
          processResults: this.processResults,
          cache: true
        },
        placeholder,
        allowClear: true
      })
    },
    processResults (data) {
      const users = data && JSON.parse(data.users).map(function (r) {
        return {
          id: r.id,
          text: r.full_name
        }
      })

      return {
        results: users
      }
    },
    processData (params) {
      return { q: params.term }
    }
  }
}
</script>
<template>
  <select
    :id="fieldId"
    class="select2 recipient-input"
    :name="name"
    :multiple="multiple"
    style="width: 100%"
  />
</template>
