<script lang="js">
// -- vendors
import Vue from 'vue'
import { isNil } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Multiselect as MultiSelect } from 'vue-multiselect'
import { mapState } from 'vuex'
// -- local
import FilterGroup from './FilterGroup.vue'
import FilterLogicOperator from './FilterLogicOperator.vue'
// import BoostrapModal from './components/boostrap-modal.vue'

// register globally
Vue.component('multi-select', MultiSelect)

export default {
  name: 'CustomReportsFilter',
  components: {
    // BoostrapModal,
    FilterGroup,
    FilterLogicOperator
  },
  props: [
    'baseUrl',
    'columns',
    'conditionOperators',
    'enumMappings',
    'isHrPortal',
    'isNew',
    'isOwner',
    'report'
  ],
  data: () => {
    return {
      nameInputDirty: false,
      name: null
    }
  },
  computed: {
    ...mapState({
      filterGroups: state => state.filterGroups,
      isNew: state => state.isNew,
      loading: state => state.requestStatus
    }),
    computedFilterGroups: function (e) {
      const array = this.$store.getters.filterGroups
      const newLogicOperator = {
        id: uuidv4(),
        type: 'filterGroupLogicOperatorType'
      }
      return array.reduce((a, b) => a.concat(b).concat(newLogicOperator), []).slice(0, -1)
    },
    isNameInputValid: function (e) {
      const valid = !(
        this.name === '' ||
        this.name === null ||
        this.name.length === 0
      )

      if (this.nameInputDirty === true) {
        return valid
      } else if (this.nameInputDirty === false && valid) {
        return true
      } else if (this.nameInputDirty === false && !valid) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    this.setEnumCollections()
    this.setIsNew()
    this.setIsOwner()
    this.setIsHrPortal()
    this.newReportLogic()
    this.overrideUrls()
    this.loadReport()
  },
  methods: {
    saveExternalButtonHandler () {
      this.saveAndUpdateReportAndQuery()
    },
    updateExternalButtonHandler () {
      this.updateQuery()
    },
    newReportLogic () {
      if (this.isNew === true) {
        this.$store.commit('newFilterGroup') // add the first filter group by default
      }
    },
    updateQuery () {
      this.$store.dispatch('ajaxUpdateFilter')
    },
    saveAndUpdateReportAndQuery () {
      this.$store.dispatch('ajaxUpdateFilter', 'save')
    },
    setDirty () {
      this.nameInputDirty = true
    },
    setEnumCollections () {
      this.$store.commit('setEnumsCollections', { enums: this.enumMappings })
    },
    setIsNew () {
      this.$store.commit('isNew', this.isNew)
    },
    setIsOwner () {
      this.$store.commit('isOwner', this.isOwner)
    },
    setIsHrPortal () {
      this.$store.commit('isHrPortal', this.isHrPortal)
    },
    overrideUrls () {
      this.$store.commit('updateBaseUrl', { url: this.baseUrl })
    },
    openNewReportModal () {
      this.$refs.boostrapModalRef.open()
      this.nameInputDirty = false
    },
    actionButtonCallbackHandler (event) {
      event.preventDefault()
      if (this.nameInputDirty === false) { this.setDirty() }
      const { isNameInputValid } = this

      if (!isNameInputValid) {
        toastr.error('please enter a valid report name')
      } else if (isNameInputValid) {
        this.createCustomReport()
      }
    },
    addFilterGroup: function (e) {
      e.preventDefault()
      this.$store.commit('newFilterGroup')
      this.$store.dispatch('updateAjaxQueryOnUiUpdate')
    },
    createCustomReport: function (e) {
      this.$store.dispatch('ajaxCreateCustomReport', { name: this.name })
    },
    loadReport: function () {
      const { report, columns, conditionOperators } = this
      if (!isNil(report)) {
        const parsedReport = JSON.parse(report)
        const { id } = parsedReport
        this.$store.dispatch('setFilterableColumnsAction', { columns })
        this.$store.dispatch('setconditionOperatorsAction', { conditionOperators })

        if (id !== null) {
          this.$store.dispatch('setNewStoreAction', { newStore: parsedReport })
        }
      }
    },
    onSubmit: function (e) {
      e.preventDefault()
    }
  }
}
</script>
<template>
  <div
    class="table-query-builder"
    :class="{'table-query-builder--hr-portal-overrides': isHrPortal === true}"
  >
    <!-- <span>status: {{ loading }}</span> -->
    <ul class="table-query-builder__filter-groups">
      <li
        v-for="filterGroup in computedFilterGroups"
        :key="filterGroup.id"
        :class="{ 'table-query-builder__filter-groups__filter-operator': filterGroup.type === 'filterGroupLogicOperatorType' }"
        class="table-query-builder__filter-groups__filter-operator"
      >
        <filter-group
          v-if="filterGroup.type === 'filterGroupType'"
          :filter-group="filterGroup"
        />
        <filter-logic-operator
          v-if="filterGroup.type === 'filterGroupLogicOperatorType'"
          :filter-type="filterGroup.type"
          :filter-group="filterGroup"
          type="globalGroup"
        />
      </li>
    </ul>
    <div class="table-query-builder__controls">
      <a
        @click="addFilterGroup"
      >
        <img
          class="table-query-builder__plus-icon"
          src="~images/custom_reports/plus-icon.svg"
        >
        Add Filter
      </a>

      <!-- <a
        @click="openNewReportModal"
      >
        Create report
      </a> -->
      <!-- <a
        v-if="!isNew"
        @click="saveAndUpdateReportAndQuery"
      >
        Save report
      </a> -->
    </div>
    <!-- <boostrap-modal
      ref="boostrapModalRef"
      action-text="Save"
      modal-title="Report name"
      action-callback="onSaveReportButtonHandler"
      @onActionButtonCallback="actionButtonCallbackHandler"
    >
      <div class="input-wrapper">
        <div class="row">
          <div class="col-sm-12">
            <p>Set a name for the custom report</p>
            <input
              id="contact_first_name"
              v-model="name"
              class="form-control"
              :class="{ 'has-error': !isNameInputValid }"
              placeholder="Name"
              required="required"
              type="text"
              aria-invalid="true"
            >
          </div>
        </div>
      </div>
    </boostrap-modal> -->
  </div>
</template>
<style src="./customReportsStyles.scss" lang="scss" />
// add vue-multiselect styles according to https://vue-multiselect.js.org
<style src="vue-multiselect/dist/vue-multiselect.min.css" lang="css" />
