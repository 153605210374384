import { v4 as uuidv4 } from 'uuid'

class BrowserIDManager {
  constructor () {
    this.cookieName = 'browser_id'
    this.browserID = this.getBrowserID()
  }

  getBrowserID () {
    const cookieValue = this.getCookie(this.cookieName)
    if (cookieValue) {
      return cookieValue
    }

    const newBrowserID = uuidv4()
    this.setCookie(this.cookieName, newBrowserID, 30)
    return newBrowserID
  }

  getCookie (name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
    return cookieValue ? cookieValue.pop() : null
  }

  setCookie (name, value, days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + date.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
  }
}

export default BrowserIDManager
