<!-- FilterGroup.vue -->
<script>
// vendor
import { v4 as uuidv4 } from 'uuid'
// local
import AddButton from './AddButton.vue'
import SingleFilter from './SingleFilter.vue'
import FilterLogicOperator from './FilterLogicOperator.vue'
export default {
  name: 'FilterGroup',
  components: {
    AddButton,
    SingleFilter,
    FilterLogicOperator
  },
  props: ['filterGroup'],
  computed: {
    computedFilterGroupFilters: function (e) {
      const array = this.$store.getters.filterGroupFilters(this.filterGroup)
      const newLogicOperator = {
        id: uuidv4(),
        type: 'singleFilterLogicOperatorType'
      }

      return array.reduce((a, b) => a.concat(b).concat(newLogicOperator), []).slice(0, -1)
    }
  },
  updated () {
    this.removeFilterGroupIfEmpty()
  },
  methods: {
    addFilter: function (event) {
      event.preventDefault()
      this.$store.commit('newFilter', this.filterGroup)
      this.$store.dispatch('updateAjaxQueryOnUiUpdate')
    },
    removeFilterGroupIfEmpty: function () {
      const { filterGroup, filterGroup: { filters } } = this
      if (filters.length === 0) {
        this.$store.commit('removeFilterGroup', filterGroup)
      }
      this.$store.dispatch('updateAjaxQueryOnUiUpdate')
    }
  }
}
</script>
<template>
  <span class="table-query-builder__filter-group-container">
    <ul class="table-query-builder__filter-group">
      <li
        v-for="filter in computedFilterGroupFilters"
        :key="filter.id"
      >
        <single-filter
          v-if="filter.type === 'singleFilterType'"
          :filter="filter"
          :filter-group="filterGroup"
        />
        <filter-logic-operator
          v-if="filter.type === 'singleFilterLogicOperatorType'"
          :filter-type="filter.type"
          :filter-group="filterGroup"
          type="filterGroup"
        />
      </li>
      <!-- <li>
      </li> -->
    </ul>
    <add-button
      :filter-group="filterGroup"
      :filter="filter"
      :add-filter="addFilter"
    />
  </span>
</template>
