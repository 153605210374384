<!-- EndLabel.vue -->
<script>
import { mapState } from 'vuex'
import { get } from 'lodash'

export default {
  props: ['filter', 'filterGroup'],
  computed: {
    ...mapState({
      conditionOperators: state => state.conditionOperators
    }),
    showEndLabel () {
      if (this.filter.label === null || this.filter.equivalency === null) { return false }
      const equivalencyType = get(this, 'filter.label.type', null)

      const label = this.filter.label
      const conditionGroup = this.conditionOperators.find(co => co.type === label.type).options
      const equivalency = conditionGroup.find(c => c.value === this.filter.equivalency.value)

      return equivalencyType === 'datetime' && equivalency.endLabel !== null
    },
    dateEndLabel () {
      const { filterGroup, filter } = this
      return this.$store.getters.filterDateEndLabel(filterGroup, filter)
    }
  }
}
</script>
<template>
  <p
    v-if="showEndLabel"
    class="table-query-builder__end-label"
  >
    {{ dateEndLabel }}
  </p>
</template>
