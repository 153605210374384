<script>
import contactSmartDocumentRequest from './contact-smart-document-request'

export default {
  name: 'contact-smart-document-requests',
  components: {
    contactSmartDocumentRequest
  },
  props: ['smartDocumentRequestsList'],
  data () {
    return {
      smartDocumentRequests: JSON.parse(this.smartDocumentRequestsList)
    }
  }
}
</script>

<template>
  <div>
    <contact-smart-document-request
      v-for="(smartDocumentRequest, index) in smartDocumentRequests"
      :key="index"
      :smartDocumentRequestItem="smartDocumentRequest"
    />
  </div>
</template>
