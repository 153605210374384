<script>
import pdf from 'vue-pdf'
import PdfPage from './PdfPage'
import { mapGetters } from 'vuex'
const axios = require('axios').default

export default {
  name: 'ESignature',
  components: {
    PdfPage
  },
  props: ['signatureDocument'],
  data() {
    return {
      pdfSrc: pdf.createLoadingTask(this.signatureDocument.document.url),
      numPages: 0,
      submitting: false
    }
  },
  mounted() {
    this.loadPdf()
  },
  methods: {
    loadPdf() {
      this.pdfSrc.promise.then(pdf => {
        this.numPages = pdf.numPages
      })
    },
    async initPdf () {
      const currentSignerId = this.signatureDocument.currentSignatureId
      const fields = await axios.get(`/signature_documents/${this.signatureDocument.id}/document_fields?current_signer_id=${currentSignerId}`)

      for(const field of fields.data) {
        const fieldRequired = parseInt(field.document_signer.id) == parseInt(currentSignerId)
        const fieldSigned = field.document_signer.submitted_at != null

        if(!fieldSigned && !fieldRequired) continue

        let fieldValue = field.value
        if(field.type == 'DateField') {
          fieldValue = new Date().toLocaleDateString("en-US")
        }

        this.$store.commit('newField', {
          _id: field.id,
          type: field.type,
          required: fieldRequired,
          signed: fieldSigned,
          pageIndex: field.page_index,
          percentWidth: parseFloat(field.percent_width),
          percentHeight: parseFloat(field.percent_height),
          percentX: parseFloat(field.percent_x),
          percentY: parseFloat(field.percent_y),
          drawnImage: field.drawn_image.url,
          value: fieldValue,
          signature: {
            id: field.document_signer.id,
            name: field.document_signer.name,
            email: field.document_signer.email
          }
        })
      }
    },
    handlePdfPageLoaded () {
      if(this.pagesLoaded == this.numPages) {
        this.initPdf()
        /*
          The vue-pdf doesn't support disabling the annotation layer. Issue related #151
          While vue-pdf support it we remove annotation layer.
        */
        document.querySelectorAll(".annotationLayer").forEach(el => el.remove())
      }
    },
    async submit () {
      if(!this.validateFieldsRequired()) {
        alert('Please complete all fields.')
        return
      }

      let data = {
        fields: []
      }

      for (const [fieldId, field] of Object.entries(this.fields)) {
        if(!field.required) continue

        data.fields.push(
          {
            id: field._id,
            type: field.type,
            drawn_image: field.drawnImage,
            value: field.value
          }
        )
      }

      try {
        this.submitting = true
        const res = await axios.put(`/signature_documents/${this.signatureDocument.id}/submit`, data)
        this.submitting = false
        window.location.href = res.data.url
      } catch(e) {
        this.submitting = false
        alert("Sorry, something went wrong. Please try again later.")
      }
    },
    validateFieldsRequired() {
      const fieldsList = Object.entries(this.fields)
      let fieldCompletedCount = 0

      for (const [fieldId, field] of fieldsList) {
        if(field.required && field.type == "SignatureField" && field.drawnImage != null) fieldCompletedCount++
        if(field.required && ["InitialsField", "DateField", "TextField"].includes(field.type) && field.value != null && field.value != "") fieldCompletedCount++
      }

      return fieldsList.length == fieldCompletedCount
    },
    close (e) {
      e.preventDefault()
      window.location.href = '/documents'
    }
  },
  computed: {
    ...mapGetters(['fields', 'pagesLoaded'])
  }
}
</script>

<template>
  <div class="row esign-sign-main-container">
    <div class="col-md-12">

      <div class="esign-sign-l-column-toolbar">
        <div class=""></div>
        <div>
          <button class="btn esign-cancel-btn" @click="close">Cancel</button>
          <button class="btn esign-continue-btn" type="button" :disabled="submitting" @click="submit">
            Submit
            <img src="https://res.cloudinary.com/docketwise/image/upload/v1666128168/e-signature/continue-arrow_s7ijfy.svg" />
          </button>
        </div>
      </div>

      <pdf-page v-for="pageIndex in numPages" :src="pdfSrc" :page-index="pageIndex" @pdf-page-loaded="handlePdfPageLoaded"/>
    </div>
  </div>
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
  @font-face {
    font-family: "DancingScript";
    src: url('../../../assets/fonts/DancingScript-Regular.ttf');
  }
  @font-face {
    font-family: "CourierPrime";
    src: url('../../../assets/fonts/CourierPrime-Regular.ttf');
  }

  .esign-sign-main-container {
    padding: 20px 30px 30px 30px;
  }
  .esign-sign-l-column-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .esign-field-not-filled {
    background-color: #FCF2CD;
    border: 3px solid #FFCA28;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-family: 'Indie Flower', cursive;
  }
  .esign-field-filled {
    background-color: #E0FFF4;
    border: 3px solid #34D39D;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  .esign-viewer-initials-field {
    font-family: 'DancingScript';
    font-size: 22px;
    padding: 4px 0px 0px 3px;
  }
  .esign-viewer-text-field {
    font-family: 'CourierPrime';
  }
  @media only screen and (max-width: 600px) {
    .esign-sign-main-container {
      padding: 10px;
    }
  }
</style>
