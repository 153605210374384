<!-- ValueDropdown.vue -->
<script>
import { get, orderBy } from 'lodash'
import { mapState } from 'vuex'

export default {
  props: ['filter', 'filterGroup', 'resetFilter'],
  computed: {
    ...mapState({
      filterableColumns: state => state.filterableColumns
    }),
    selectValue () {
      const { filterGroup, filter } = this
      const selectValue = this.$store.getters.filterSelectValue(filterGroup, filter)

      return selectValue
    },
    enumOptions () {
      const { filter } = this
      const filterLabelValue = get(filter, 'label.value', null)
      const enumValue = this.filterableColumns.find(lo => lo.value === filterLabelValue)
      const enumsCollections = this.$store.getters.enumsCollections()
      let enumOptions

      if (enumValue.custom_attribute_id !== undefined) {
        enumOptions = enumsCollections[enumValue && enumValue.custom_attribute_id]
      } else {
        enumOptions = enumsCollections[enumValue && enumValue.value]
      }
      const options = orderBy(enumOptions, ['name'], ['asc'])
      return (options) || []
    }
  },
  methods: {
    updateSelectValue: function (selectValue) {
      const { filterGroup, filter } = this
      this.$store.commit('updateFilterSelectValue', { filter, filterGroup, selectValue })
    }
  }
}
</script>
<template>
  <multi-select
    v-model="selectValue"
    class="table-query-builder__value-selector"
    :allow-empty="false"
    :show-labels="false"
    :placeholder="'Select an option'"
    label="name"
    track-by="name"
    :options="enumOptions"
    @select="updateSelectValue"
  />
</template>
