<script>
import Vue from 'vue'
import VueSignaturePad from 'vue-signature-pad'
Vue.use(VueSignaturePad)

export default {
  name: 'SignatureFieldModal',
  props: ['field'],
  data() {
    return {
      type: this.field.type,
      initialsValue: '',
      consent: false
    }
  },
  methods: {
    hideModal: function () {
      let newField = this.field
      newField.show = false
      this.$store.commit('updateField', newField)
      this.consent =  false
      this.initialsValue = ''
    },
    setSignature: function () {
      if(!this.consent) return

      let newField = this.field
      if(this.type == 'SignatureField') {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
        newField.drawnImage = data
        newField.type = this.type
        newField.value = null
      } else {
        newField.value = this.initialsValue
        newField.type = this.type
        newField.drawnImage = null
      }

      newField.show = false
      this.$store.commit('updateField', newField)
      this.consent =  false
      this.initialsValue = ''
    },
    cleanSignatureDrawing() {
      this.$refs.signaturePad.undoSignature();
    },
  },
  computed: {
    consentStatusClass: function () {
      return this.consent ? "" : "disabled"
    }
  }
}
</script>

<template>
  <div class="modal show" tabindex="-1" role="dialog" v-if="field.show">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="hideModal"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" v-if="field.type == 'SignatureField'">Enter Your Signature</h4>
          <h4 class="modal-title" v-else>Enter Your Initials</h4>
        </div>
        <div class="modal-body mt-0 pb-0">
          <form>
            <div class="radio">
              <label>
                <input type="radio" name="type" value="SignatureField" v-model="type">
                Draw
              </label>
            </div>

            <div class="radio">
              <label>
                <input type="radio" name="type" value="InitialsField" v-model="type">
                Type
              </label>
            </div>

            <div class="form-group" v-if="type == 'SignatureField'">
              <label>Draw</label>
              <vue-signature-pad
                id="signature"
                ref="signaturePad"
                width="100%"
                height="160px"
              />
              <div class="text-right">
                <button type="button" class="btn btn-link" @click="cleanSignatureDrawing">Clear</button>
              </div>
            </div>

            <div class="form-group" v-if="type == 'InitialsField'">
              <label for="initialsValue">Text</label>
              <input type="text" name="initialsValue" class="form-control" v-model="initialsValue"/>
            </div>

            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="consent"> I am {{ field.signature.name }}, and I consent to be legally bound by this electronic signature - just the same as a pen-and-paper signature.
              </label>
            </div>
          </form>
        </div>
        <div class="modal-footer pb-5">
          <button type="button" class="btn btn-default" @click="hideModal">Cancel</button>
          <button type="button" class="btn btn-primary" :class="{ disabled: !consent }" @click="setSignature">Sign</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
  <div v-if="field.show" class="modal-backdrop fade in"></div>
</template>

<style lang="scss">
  #signature {
    border: 1px solid #000;
    border-radius: 5px;
  }
</style>
