import TextMessagingButtonOpenDashboard from '../text_messaging/tm_button_open_dashboard'
import TextMessagingDashboard from '../text_messaging/tm_dashboard'
import '../text_messaging/tm_new_conversation_modal'
import '../text_messaging/tm_edit_conversation_modal'
import '../text_messaging/paginator'

export default function initTextMessaging ({ pusherKey, firmId, textMessagingEnabled, pusherTimeoutFetch, textMessagingTimeZone }) {
  // set bus and use it in Vue apps to share methods
  // https://stackoverflow.com/questions/43059067/is-it-possible-to-push-data-from-one-vue-app-to-another
  const bus = new Vue()
  new TextMessagingDashboard({ bus, textMessagingEnabled, pusherKey, pusherTimeoutFetch, firmId, textMessagingTimeZone })
  new TextMessagingButtonOpenDashboard({ textMessagingEnabled, bus })
};
