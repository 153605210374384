<!-- SingleFilter.vue -->
<script>
// vendor
import { mapState } from 'vuex'
// local

// import ValueDatePicker from './ValueDatePicker.vue'
import EndLabel from './EndLabel.vue'
import EquivalencyDropdown from './EquivalencyDropdown.vue'
import InputDateValue from './InputDateValue.vue'
import InputTextValue from './InputTextValue.vue'
import LabelDropdown from './LabelDropdown.vue'
import RemoveButton from './RemoveButton.vue'
import ValueDropdown from './ValueDropdown.vue'

export default {
  components: {
    EndLabel,
    EquivalencyDropdown,
    InputDateValue,
    InputTextValue,
    LabelDropdown,
    RemoveButton,
    // ValueDatePicker,
    ValueDropdown
  },
  props: ['filter', 'filterGroup'],
  computed: {
    ...mapState({
      filterableColumns: state => state.filterableColumns
    }),
    showLabelDropdown () {
      return true
    },
    showEquivalencyDropdown () {
      const { filter } = this
      if (filter && filter.label === null) {
        return false
      } else { return true }
    },
    hasError () {
      const { filter } = this

      if (filter && filter.error === undefined) {
        return false
      } else { return true }
    },
    showInputTextValue () {
      const { filter } = this
      // when a column is selected and
      // when is string or
      // when is interger
      // return false
      if (filter && filter.label === null) {
        return false
      } else if (filter && filter.equivalency === null) {
        return false
      } else if (
        filter && filter.label && filter.label.type === 'string' &&
        (filter.equivalency.value !== 'is_unknown' && filter.equivalency.value !== 'has_any_value')
      ) {
        return true
      } else if (
        filter && filter.label && filter.label.type === 'integer' &&
        (filter.equivalency.value !== 'is_unknown' && filter.equivalency.value !== 'has_any_value')
      ) {
        return true
      } else if (
        (
          (filter && filter.equivalency.value === 'greater_than') ||
          (filter && filter.equivalency.value === 'less_than')
        ) &&
        filter.label.type === 'datetime'
      ) {
        return true
      }
      return false
    },
    showInputDateValue () {
      // when is datetime
      const { filter } = this

      if (filter && filter.label === null) {
        return false
      } else if (filter && filter.equivalency === null) {
        return false
      } else if (filter && filter.equivalency && filter.equivalency.value === 'is_unknown') {
        return false
      } else if (filter && filter.equivalency && filter.equivalency.value === 'has_any_value') {
        return false
      } else if (
        (
          (filter && filter.equivalency.value === 'greater_than') ||
          (filter && filter.equivalency.value === 'less_than')
        ) &&
        filter.label.type === 'datetime'
      ) {
        return false
      } else if (filter && filter.label && filter.label.type === 'datetime') {
        return true
      }
      return false
    },
    showValueDropdown () {
      const { filter } = this

      if (filter && filter.label === null) {
        return false
      } else if (filter && filter.equivalency === null) {
        return false
      } else if (filter && filter.equivalency && filter.equivalency.value === 'is_unknown') {
        return false
      } else if (filter && filter.equivalency && filter.equivalency.value === 'has_any_value') {
        return false
      } else if (filter && filter.label && filter.label.type === 'enum') {
        return true
      }
      return false
    },
    showEndLabel () {
      const { filter } = this

      if (filter && filter.label === null) {
        return false
      } else if (filter && filter.equivalency === null) {
        return false
      } else if (
        (
          (filter && filter.equivalency.value === 'greater_than') ||
          (filter && filter.equivalency.value === 'less_than')
        ) &&
        filter.label.type === 'datetime'
      ) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    checkForm: function (event) {
      event.preventDefault()
    },
    resetFilter: function () {
      const { filterGroup, filter } = this
      this.$store.commit('resetFilter', { filter, filterGroup })
    },
    removeFilter: function (filter) {
      const { filterGroup } = this
      this.$store.commit('removeFilter', filter, filterGroup)
    },
    updateQuery () {
      this.$store.dispatch('debounceAjaxUpdateFilter')
    }
  }
}
</script>
<template>
  <div
    class="table-query-builder__filter"
    :class="{'table-query-builder__filter--error': hasError}"
  >
    <form
      class="table-query-builder__filter__form"
      @submit.prevent="checkForm"
    >
      <label-Dropdown
        v-if="showLabelDropdown"
        :filter="filter"
        :filterable-columns="filterableColumns"
        :filter-group="filterGroup"
        :reset-filter="resetFilter"
      />
      <equivalency-Dropdown
        v-if="showEquivalencyDropdown"
        :filter="filter"
        :filter-group="filterGroup"
      />
      <input-text-value
        v-if="showInputTextValue"
        :filter="filter"
        :filter-group="filterGroup"
      />
      <input-date-value
        v-if="showInputDateValue"
        :filter="filter"
        :filter-group="filterGroup"
      />
      <value-dropdown
        v-if="showValueDropdown"
        :filter="filter"
        :filter-group="filterGroup"
      />
      <!-- <ValueDatePicker
        :filter="filter"
        :filter-group="filterGroup"
      /> -->
      <end-label
        v-if="showEndLabel"
        :filter="filter"
        :filter-group="filterGroup"
      />
    </form>
    <remove-button
      :filter-group="filterGroup"
      :filter="filter"
      :remove-filter="removeFilter"
    />
  </div>
</template>
