import utils from '../text_messaging/tm_utilities'

Vue.component('tm-new-conversation-modal', {
  props: [
    'fetchConversations',
    'fetchConversation',
    'selectConversation',
    'displayError'
  ],
  mounted () {
    // modal
    const textMessagingNewConversationModal = $(
      '#text-messaging-new-conversation-modal'
    )
    // form
    const textMessagingNewConversationModalForm = $(
      '#text-messaging-new-conversation-modal-form'
    )
    // inputs
    const textMessagingRecipientInputId =
      '#text-messaging-new-conversation-recipient-input'
    const textMessagingRecipientInput = $(textMessagingRecipientInputId)
    const textMessagingClientInput = $(
      '#text-messaging-new-conversation-client-input'
    )
    const textMessagingMatterInput = $(
      '#text-messaging-new-conversation-matter-input'
    )
    const textMessagingPhoneInput = $(
      '#text-messaging-new-conversation-phone-input'
    )
    const textMessagingAddClientOrMatterCheckbox = $(
      '#text-messaging-new-conversation-link-to-client-or-matter-checkbox'
    )
    // inputs wrappers
    const textMessagingNewConversationClientWrapper = $(
      '#text-messaging-new-conversation-client-input-wrapper'
    )
    const textMessagingNewConversationPhoneWrapper = $(
      '#text-messaging-new-conversation-phone-input-wrapper'
    )
    const textMessagingNewConversationMatterWrapper = $(
      '#text-messaging-new-conversation-matter-input-wrapper'
    )
    const textMessagingNewConversationCheckboxAddClientAndOrMatterWrapper = $(
      '#text-messaging-new-conversation-add-client-or-matter-checkbox-wrapper'
    )
    const textMessagingNewConversationClientAndMatterWrapper = $(
      '#text-messaging-new-conversation-add-client-or-matter-inputs-wrapper'
    )
    // select2 instances
    const textMessagingNewConversationSelect2Recipient = new ApplicationParty()
    const textMessagingNewConversationSelect2Matter = new Matter()

    // initialize select2 inptus
    textMessagingNewConversationSelect2Recipient.initializeSelect2(
      '/contacts/new',
      textMessagingRecipientInputId,
      true,
      textMessagingNewConversationModal
    )
    textMessagingNewConversationSelect2Recipient.displayClientModal(
      '/contacts/new',
      false,
      textMessagingRecipientInputId
    )
    textMessagingNewConversationSelect2Matter.initializeSelect2(
      '/matters/new',
      '#text-messaging-new-conversation-matter-input',
      '#text-messaging-new-conversation-client-input',
      () => $('#text-messaging-new-conversation-client-input').val(),
      textMessagingNewConversationModal
    )
    textMessagingNewConversationSelect2Matter.displayMatterModal(
      '/matters/new',
      '#text-messaging-new-conversation-matter-input',
      '#text-messaging-new-conversation-client-input'
    )
    textMessagingClientInput.select2(
      utils.dynamicConfig({
        allowClear: false,
        dropdownParent: textMessagingNewConversationModal,
        placeholder: 'Select a client',
        removeCreateNewContact: true
      })
    )
    // first time load: hide all but wrappers recipient select2
    textMessagingNewConversationClientWrapper.hide()
    textMessagingNewConversationCheckboxAddClientAndOrMatterWrapper.hide()
    textMessagingNewConversationClientAndMatterWrapper.hide()

    // -- input behaviour driven logic --
    textMessagingRecipientInput.on('select2:unselecting', function (e) {
      // reset
      utils.resetSelect2(textMessagingClientInput)
      utils.resetSelect2(textMessagingMatterInput)
      textMessagingPhoneInput.val(null)
      textMessagingAddClientOrMatterCheckbox.prop('checked', false)
      // hide
      textMessagingNewConversationMatterWrapper.hide()
      textMessagingNewConversationClientWrapper.hide()
      textMessagingNewConversationPhoneWrapper.hide()
      textMessagingNewConversationClientAndMatterWrapper.hide()
      textMessagingNewConversationCheckboxAddClientAndOrMatterWrapper.hide()
    })

    textMessagingRecipientInput.on('select2:open', () => {
      setTimeout(() => {
        $('.select2-search__field').focus()
      }, 128)
    })

    textMessagingClientInput.on('select2:open', () => {
      setTimeout(() => {
        $('.select2-search__field').focus()
      }, 128)
    })

    textMessagingMatterInput.on('select2:open', () => {
      setTimeout(() => {
        $('.select2-search__field').focus()
      }, 128)
    })

    textMessagingRecipientInput.on('select2:select', function () {
      const recipientInputData = $(
        '#text-messaging-new-conversation-recipient-input'
      ).select2('data')

      if (utils.select2HasValue(recipientInputData)) {
        // get data from selected recipent
        const recipientInputId = _.get(recipientInputData, '[0].id', null)
        const recipientInputLabel = _.get(
          recipientInputData,
          '[0].text',
          null
        )
        const recipientInputPhone = _.get(
          recipientInputData,
          '[0].number',
          null
        )
        // show phone input and link to client/matter checkbox
        textMessagingNewConversationPhoneWrapper.show()
        textMessagingNewConversationCheckboxAddClientAndOrMatterWrapper.show()
        textMessagingClientInput.show()
        textMessagingMatterInput.show()
        // set phone number at input if available from recipient
        textMessagingPhoneInput.val(
          recipientInputPhone === null
            ? ''
            : utils.cleanPhoneInput(recipientInputPhone)
        )
        textMessagingPhoneInput.trigger('change')
        // match selected recipient in client input
        utils.resetSelect2(textMessagingClientInput)
        const clientNewOption = `<option data-phone-number=${recipientInputPhone} value="${recipientInputId}">${recipientInputLabel}</option>`
        textMessagingClientInput.append(clientNewOption)
        textMessagingClientInput.select2('data', {
          id: recipientInputId,
          label: recipientInputLabel
        })
        textMessagingClientInput.trigger('change')
      }
    })

    textMessagingAddClientOrMatterCheckbox.on('change', function (e) {
      if ($(this).prop('checked') === true) {
        textMessagingNewConversationClientAndMatterWrapper.show()
      }

      if ($(this).prop('checked') === false) {
        textMessagingNewConversationClientAndMatterWrapper.hide()
      }
    })

    textMessagingClientInput.on('change', function (e) {
      utils.resetSelect2(textMessagingMatterInput)
      const textMessagingClientInputData =
        textMessagingClientInput.select2('data')

      if (utils.select2HasValue(textMessagingClientInputData)) {
        textMessagingNewConversationMatterWrapper.show()
        textMessagingNewConversationClientWrapper.show()
        textMessagingNewConversationPhoneWrapper.show()
        textMessagingNewConversationCheckboxAddClientAndOrMatterWrapper.show()
      }
    })

    textMessagingPhoneInput.on('keyup change', function (e) {
      this.value = utils.cleanPhoneInput(this.value)
    })

    const createConversation = () => {
      const that = this
      const recipient = textMessagingRecipientInput.select2('data')
      const client = textMessagingClientInput.select2('data')
      const phone = textMessagingPhoneInput.val()
      const matter = textMessagingMatterInput.select2('data')
      const sendClientOrMatter =
        textMessagingAddClientOrMatterCheckbox.prop('checked')
      let conversationObject
      $('.action-conversation-btn').removeAttr('disabled')

      if (sendClientOrMatter === true) {
        conversationObject = {
          recipient_id: _.get(recipient, '[0].id', null),
          client_id: _.get(client, '[0].id', null),
          matter_id: _.get(matter, '[0].id', null),
          recipient_mobile_phone: '+1' + phone.replace(/[^0-9.]/g, '')
        }
      } else {
        conversationObject = {
          recipient_id: _.get(recipient, '[0].id', null),
          client_id: _.get(recipient, '[0].id', null),
          recipient_mobile_phone: '+1' + phone.replace(/[^0-9.]/g, '')
        }
      }

      $.ajax({
        type: 'POST',
        url: '/conversations',
        data: { conversation: conversationObject },
        success: function (response) {
          const { id } = response
          that.fetchConversations(() => {
            textMessagingNewConversationModal.modal('hide')
            toastr.success('Conversation created succesfuly')
            that.selectConversation(id)
            that.fetchConversation({ id, reset: true, goToTop: true })
          })
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          $('.action-conversation-btn').removeAttr('disabled')
          const errObj = XMLHttpRequest.responseJSON
          that.displayError(errObj)
        }
      })
    }

    textMessagingNewConversationModalForm.submit(function (event) {
      event.preventDefault()
      createConversation()
    })
  },
  template: '#text-messaging-new-conversation-modal-container'
})
