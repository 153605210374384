<!-- AjaxInput.vue -->
<script>
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ajax-input',
  props: ['initialText', 'customReportId'],
  data: () => {
    return {
      inputValue: null,
      initualInputValue: null,
      focus: false
    }
  },
  mounted () {
    this.inputValue = this.initialText
    this.initualInputValue = this.initialText
    $('#custom-reports-title-input-ajax__editable-span').keydown((e) => {
    // trap the return key being pressed
      if (e.keyCode === 13) {
        this.placeCaretAtInputsEnd()
        // insert 2 br tags (if only one br tag is inserted the cursor won't go to the next line)
        document.execCommand('insertHTML', false, '<br/>')
        // prevent the default behaviour of return key pressed
        return false
      }
    })
  },
  methods: {
    updateLinkTitle  (str, n) {
      $(`[data-report-id="${this.customReportId}"]`).attr('title', this.inputValue)
      $(`[data-report-id="${this.customReportId}"]`).text(this.truncate(this.inputValue, 30))
      $('#custom-report-breadcrumb-title').text(this.truncate(this.inputValue, 30))
    },
    truncate (str, n) {
      return (str.length > n) ? str.slice(0, n - 3) + '...' : str
    },
    onBlurHandler () {
      this.inputValue = $('#custom-reports-title-input-ajax__editable-span').text()
      this.updateName()
    },
    onEnterHandler () {
      $('#custom-reports-title-input-ajax__editable-span').blur()
    },
    placeCaretAtEnd (el) {
      el.focus()
      if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
        const range = document.createRange()
        range.selectNodeContents(el)
        range.collapse(false)
        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      } else if (typeof document.body.createTextRange !== 'undefined') {
        const textRange = document.body.createTextRange()
        textRange.moveToElementText(el)
        textRange.collapse(false)
        textRange.select()
      }
    },
    placeCaretAtInputsEnd () {
      const input = this.$refs.title
      const { placeCaretAtEnd } = this
      setTimeout(function () {
        placeCaretAtEnd(input)
      }, 50)
    },
    async updateName () {
      const obj = {
        custom_reports: { name: this.inputValue }
      }

      try {
        await axios.patch(`/custom_reports/${this.customReportId}`, obj)
        const csrfToken = document.getElementsByName('csrf-token')[0].content
        await axios.get(`/custom_reports/${this.customReportId}`, {
          responseType: 'text',
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'text/javascript',
            Accept: 'text/javascript'
          }
        })

        toastr.success('Report name was updated')
        $('#custom-reports-title-input-ajax__editable-span').blur()
        this.updateLinkTitle()
      } catch (err) {
        this.inputValue = this.initualInputValue
        toastr.error('Report name was not updated')
        console.error(err)
        $('#custom-reports-title-input-ajax__editable-span').blur()
      }
    }
  }
})
</script>
<template>
  <span id="custom-reports-title-input-ajax__container">
    <span
      id="custom-reports-title-input-ajax__editable-span"
      ref="title"
      class="input"
      role="textbox"
      contenteditable="true"
      @keyup.enter="onEnterHandler"
      @blur="onBlurHandler"
    >
      {{ inputValue }}
    </span>

    <a @mousedown="placeCaretAtInputsEnd">
      <img
        class="custom-reports-title-input-ajax__edit-pencil"
        src="~images/custom_reports/edit-pencil.svg"
      >
    </a>
  </span>
</template>
<style src="./ajax-input.scss" lang="scss" />
