<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'SignatureInput',
  components: {
    Multiselect
  },
  props: ['field'],
  computed: {
    ...mapGetters(['signers'])
  }
}
</script>

<template>
  <div>
    <label class="esign-field-details-label">Assigned to</label>
    <multiselect
      placeholder="Select signer"
      v-model="field.signature"
      label="name"
      track-by="id"
      :options="signers"
      open-direction="bottom"
      :searchable="false"
      :group-select="false"
      :show-labels="false"
      :show-pointer="false"
      :allow-empty="false">
    </multiselect>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css" lang="css" />
