<!-- InputDateValue.vue -->
<script>
export default {
  props: ['filter', 'filterGroup'],
  data: function () {
    return {
      dateFormat: 'mm/dd/yyyy'
    }
  },

  computed: {
    dateValue () {
      const { filterGroup, filter } = this
      const dateValue = this.$store.getters.filterDateValue(filterGroup, filter)

      return dateValue
    }
  },
  mounted: function () {
    const { dataFormat } = this

    $('.table-query-builder__filter__input-date').inputmask(dataFormat)
  },
  methods: {
    updateDateValue: function (dateValue) {
      const { filterGroup, filter } = this
      this.$store.commit('updateFilterDateValue', { filter, filterGroup, dateValue })
    },
    enterHandler: function (event) {
      event.target.blur()
      const dateValue = event.target.value
      this.updateDateValue(dateValue)
    }
  }
}
</script>
<template>
  <input
    class="table-query-builder__filter__input-date"
    data-inputmask-alias="mm/dd/yyyy"
    data-val="true"
    data-val-required="Required"
    name="DATE"
    placeholder="mm/dd/yyyy"
    type="text"
    :value="dateValue"
    @keyup.enter="enterHandler"
    @blur="enterHandler"
  >
</template>
