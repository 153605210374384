import { get } from 'lodash'
import { createApp } from 'vue'

import CustomReportsFilter from '../custom_reports_filter/CustomReportsFilter.vue'
import AjaxInput from '../custom_reports_filter/components/ajax-input.vue'
import store from '../custom_reports_filter/store'

const initCoreFiltersUI = () => {
  // init custom reports
  const customReportsId = '#filter-custom-reports'
  const customReportsEl = document.querySelector(customReportsId)
  const baseUrl = get(customReportsEl, 'dataset.baseUrl', null)
  const conditionOperators = get(customReportsEl, 'dataset.conditionOperators', null)
  const customColumns = get(customReportsEl, 'dataset.columns', null)
  const customReport = get(customReportsEl, 'dataset.report', null)
  const enumMappings = get(customReportsEl, 'dataset.enumMappings', null)
  const isNew = get(customReportsEl, 'dataset.isNew', null)
  const isOwner = get(customReportsEl, 'dataset.isOwner', null)
  const isHrPortal = get(customReportsEl, 'dataset.isHrPortal', null)

  // eslint-disable-next-line vue/one-component-per-file
  const customReportApp = createApp(CustomReportsFilter, {
    baseUrl,
    columns: customColumns,
    conditionOperators,
    enumMappings,
    isHrPortal: JSON.parse(isHrPortal),
    isNew: JSON.parse(isNew),
    isOwner: JSON.parse(isOwner),
    report: customReport
  }).use(store).mount(customReportsId)

  $('body').on('click', '.custom-reports__export-save-button', function () {
    customReportApp.saveExternalButtonHandler()
  })

  $('body').on('click', '.custom-reports__update-filter-button', function () {
    customReportApp.updateExternalButtonHandler()
  })
}

const initTitleAjax = () => {
  const customAjaxInput = document.querySelector('#input-edit-custom-report-name')
  const customReportId = get(customAjaxInput, 'dataset.customReportId', null)
  const initialText = get(customAjaxInput, 'dataset.initialText', null)

  // eslint-disable-next-line vue/one-component-per-file
  createApp(AjaxInput, { initialText, customReportId }).mount('#input-edit-custom-report-name')
}

window.initCoreFiltersUI = initCoreFiltersUI
window.initTitleAjax = initTitleAjax
