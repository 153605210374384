<script>
import Vue from 'vue'
import VueSignaturePad from 'vue-signature-pad'
Vue.use(VueSignaturePad)

export default {
  name: 'TextFieldModal',
  props: ['field'],
  data() {
    return {
      value: null
    }
  },
  methods: {
    hideModal: function () {
      let newField = this.field
      newField.textFieldModal = false
      this.$store.commit('updateField', newField)
      this.value = null
    },
    save: function () {
      let newField = this.field
      newField.value = this.value
      newField.textFieldModal = false
      this.$store.commit('updateField', newField)
    }
  }
}
</script>

<template>
  <div class="modal show" tabindex="-1" role="dialog" v-if="field.textFieldModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="hideModal"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Enter your text</h4>
        </div>
        <div class="modal-body mt-0 pb-0">
          <form>
            <div class="form-group">
              <label>Text</label>
              <input type="text" name="value" class="form-control" v-model="value"/>
            </div>
          </form>
        </div>
        <div class="modal-footer pb-5">
          <button type="button" class="btn btn-default" @click="hideModal">Cancel</button>
          <button type="button" class="btn btn-primary" @click="save">Save</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
  <div v-if="field.textFieldModal" class="modal-backdrop fade in"></div>
</template>
