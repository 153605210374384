const addEventListenerCancel = () => {
  // close window button at smart forms for my case
  $('body').on('click', '.dw-sf-topbar-cancel-btn', function (event) {
    event.preventDefault()
    window.close()
  })
}

const addEventListenerCancelAfterAjaxCall = () => {
  $(document).ajaxSuccess(function () {
    addEventListenerCancel()
  })

  $(document).on('show.bs.modal', '.modal', function () {
    addEventListenerCancel()
  })
}

export const mycaseLogic = () => {
  addEventListenerCancel()
  addEventListenerCancelAfterAjaxCall()
  addOverrideClassForSmartFormsQuestionTitle()
}

function initWhitelabel ({ whiteLabelBrand }) {
  switch (whiteLabelBrand) {
    case 'mycase':
      mycaseLogic()
      break
  }
}

function addOverrideClassForSmartFormsQuestionTitle () {
  $('#smart-form-assembly .question-container input[type=radio]').closest('.col-md-1').siblings('.col-md-9').addClass('dw-col-question-title')
}

const whiteLabels = {
  initWhitelabel
}

export default whiteLabels
